const english = {
  login: {
    contryLabel: "COUNTRY",
    contryPlaceHolder: "",
    phoneLable: "PHONE NUMBER",
    phonePlaceHolder: "PHONE NUMBER",
    validationLabel: "Please enter valid phone number in English",
    btnLabel: "Continue",
    tokenSuccessLabel: "Your OTP has been sent to your phone",
    tokenSuccessFailed: "Your OTP not create yet please try again",
    langChange: "Change language to",
    termsAllow: "Please allow terms and conditions while logging in!",
  },
  password: {
    passwordLabel: "PASSWORD",
    passwordPlaceHolder: "Enter your password",
    forgetPass: "Forgot Password",
    backWord: "Go Back",
    btnLabel: "Login",
    otpSendLabel: "OTP has been sent to your mobile",
    passwordNotMatch: "Password not matched",
  },
  changePass: {
    passwordLabel: "NEW PASSWORD",
    passwordPlaceHolder: "*******************",
    confirmPassLabel: "CONFIRM PASSWORD",
    confirmPassPlaceHolder: "*******************",
    passNotMatchLabel: "Password does not match",
    btnLabel: "Confirm Password",
    successLabel: "Your password Changed successfully",
    errorLabel: "Your password Changed failed",
    lengthErrorLabel: "Password must be at least 4 characters long",
    notMatchLabel: "Your password and confirm password not match.",
    backBtnLabel: "Back to mein site",
  },
  token: {
    tokenLabel: "OTP",
    tokenPlaceHolder: "Enter your OTP",
    notGet: "Didn’t recieve code?",
    tokenResend: "Request again",
    tokenSuccessLabel: "Code has been sent to your mobile, type to continue",
    tokenErrorLabel: "OTP send failed",
    timerLabel: "Please wait",
    second: "second",
    btnLabel: "Confirm OTP",
    tokenNotMatch: "Your OTP code is wrong",
    tokenVarified: "Your OTP has been verified",
    tokenNotWrite: "Please enter your valid OTP",
  },
  registration: {
    nameLabel: "FULL NAME",
    namePlaceholder: "Enter your full name",
    createProfile: "Create Your Profile",
    helpUs: "Please help us by providing correct information",
    emailLabel: "EMAIL",
    emailPlaceholder: "Enter your email",
    passwordLabel: "PASSWORD",
    passwordPlaceholder: "Enter your password",
    confirmPassLabel: "CONFIRM PASSWORD",
    confirmPassPlaceholder: "Enter your confirm password",
    validationLabel: "Input field must be at least 4 characters long",
    passNotMatch: "Your password and confirm password not match.",
    btnLabel: "Continue",
    successMessage: "Profile Created Successfully",
    errorMessage: "Please check valid input",
    passLengthValidLabel: "Password must be at least 4 characters long",
    dobLabel: "DATE OF BIRTH",
    day: "Day",
    month: "Month",
    year: "Year",
    genderLabel: "GENDER",
    male: "Male",
    female: "Female",
    others: "Others",
    passwordLength: "Your password is",
    strong: "Strong",
    medium: "Medium",
    weak: "Weak",
  },
  letNav: {
    homeMenuLabel: "Home",
    taskMenuLabel: "Task List",
    analyticsMenuLabel: "Contract Management",
    referMenuLabel: "Referral",
    orderMenuLabel: "Order History",
    mySupplierMenuLabel: "My Suppliers",
    businessProfileMenuLabel: "Business Profile Settings",
    phoneCall: "Call To Order",
  },
  jobSelect: {
    titlePart1: "Welcome to our EkkHero Community.",
    ekkHero: "EkkHero",
    titlePart2: " you can complete your task and ",
    earnMoney: "Earn money.",
    sales: "Sales",
    delivery: "Delivery",
    others: "Others",
    btnLabel: "Next",
    navLabel: "Select one or more",
  },
  home: {
    showMoreLabel: "Show More",
    registerBtnLabel: "Register",
    updateBtnLabel: "Update Information",
    brandLabel: "Brand",
    offerLabel: "New Offer",
    mySuppliersLabel: "My Suppliers",
    nearSuppliersLabel: "Nearby Suppliers",
    recommendentLabel: "Just For You",
    searchLabel: "Search products",
    todayPurchaseLabel: "Total Customer",
    totalOrderLabel: "Products from",
    totalOrder: "Orders",
    skusLabel: "SKUs",
    tillNow: "Today's Visit",
    orderDue: "Orders Due",
    brandProductPageTitle: "All Brands",
    offerProductPageTitle: "Offer Products",
    offerProductNotFound: "Sorry, No offers were found for you.",
    mySupplierNotFound:
      "You dont have any suppliers. Please buy products to add your suppliers here.",
    supplierNotFound:
      "Sorry, no suppliers were found in your area, We will assign some suppliers in your area very soon.",
    brandSeller: "Suppliers",
    allBrandSeller: "All Suppliers",
    km: "km",
    step1Title: "Step 1 - Register as a EkkHero",
    step1Content:
      "Submit your information to be approved as EkkHero. You cannot apply to any job until you register.",
    step2Title: "Step 2 - Wait for your ID Verification",
    step2Content:
      "When finish your register wait fo your ID Verification. Registers button & profile will turn green when your id verification is done.",
    step3Title: "Step 3 - Apply for Task/Job",
    step3Content: "View the task/job list and apply for them.",
    recommendentionLabel: "Learning",
    applyNowBtnLabel: "Apply Now",
    clickInfoLabel: "Click green “Apply Now” button for do this job.",
    jobDetailsTitle: "Job/Task Details",
  },
  addressVerify: {
    nidPlaceholder1: "NID - Front Side",
    nidPlaceholder2: "NID - Back Side",
    nidSuccessMessage: "NID Uploaded Successfully!",
    nidPlaceholder3: "Upload your image here, or browse",
    nidPlaceholder4: "Maximum file size support 2MB, JPG, JPEG, PNG or PDF",
    mainTitle: "Account Verify",
    addressTitlePart1: "Please give your",
    addressTitlePart2: "Correct Information",
    presentAddressLabel: "Present Address",
    presentAddressPlaceholder: "Enter your full address",
    presentCityLabel: "Present City",
    presentCityPlaceholder: "City",
    postalCodeLabel: "Postal Code",
    postalCodePlaceholder: "Postal Code",
    nameLabel: "Name",
    namePlaceholder: "Enter your father/mother/brother name",
    numberLabel: "Number",
    numberPlaceholder: "Enter your father/mother/brother number",
    extraLabel: "for Emergency Contact",
    cancel: "I will give this information later",
    successMessage: "Address saved successfully !",
    gaurdianNumberError: "Please enter gaurdian number !",
    postalCodeError: "Please enter postal code !",
    cityError: "Please enter city name !",
    presentAddressError: "Please enter present address !",
  },
  workVerify: {
    workVerifyTitle: "Work Type & Salary",
    workTypeLabel: "Work Type",
    workTypeOption1: "Part Time",
    workTypeOption2: "Full Time",
    previousWorkExperienceLabel: "Previous Work Experience",
    previousWorkExperienceOption1: "Yes",
    previousWorkExperienceOption2: "No",
    monthlySalaryLabel: "Monthly Salary",
    monthlySalaryLabel2: "Expected from EkkBaz",
    monthlySalaryPlaceholder: "Enter your expected monthly salary",
    workAreaLabel: "District",
    workAreaLabel3: "Sub District/Thana",
    workAreaLabel2: "Interested",
    workAreaPlaceholder: "Select your interested work area",
    workAreaPlaceholderWarning: "Please select a District first",
    academicQualificationLabel: "Educational Qualification",
    academicQualificationLabel2: "Please Select Your Academic Education level",
    academiOption1: "Class 1 to 5 ( PSC )",
    academiOption2: "Class 1 to 8  ( JSC )",
    academiOption3: "Class 1 to 10 ( SSC )",
    academiOption4: "Class 1 to 12 ( HSC )",
    academiOption5: "Others",
    addFileLabel: "Add Your CV",
    addFileLabel2: "Please upload a PDF file",
    addFilePlaceholder: "Browse File",
    successMessage: "Work & Salary saved Successfully!",
    workError: "Please select job type !",
    experienceError: "Please select previous work experience !",
    districtError:
      "Please select the district you are interested in working for !",
    upazillaError:
      "Please select the upazila/ police station you are interested in working for !",
  },
  skillVerify: {
    skillVerifyTitle: "Industry & Skill",
    industryLabel: "Working Industry",
    industryLabel2: "Please select your working industry",
    food: "Food",
    fashion: "Fashion",
    pharmacy: "Pharmacy",
    marketing: "Marketing",
    electronics: "Electronics",
    digitalServices: "Digital Services",
    it: "IT",
    design: "Design",
    banking: "Banking",
    insurance: "Insurance",
    cosmetics: "Cosmetics",
    logistics: "Logistics",
    skillLabel: "Skill",
    skillLabel2: "Please select your best skill",
    driving: "Strong driving record",
    navigation: "Navigation",
    customerService: "Customer Service",
    vehicle: "Vehicle maintenance knowledge",
    fitness: "Physical fitness & strength",
    problemSolving: "Problem Solving",
    traffic: "Knowledge of traffic laws",
    work: "Work alone effectively",
    successMessage: "Industry & Skill Saved Successfully !",
  },
  drivingVerify: {
    drivingTitle: "Driving & Vehicle",
    haveDrivingLabel: "Do you have any driving license?",
    licenceNumberLabel: "Driving licence number",
    licenceNumberPlaceholder: "Enter your driving license number",
    haveVehicleLabel: "Do you have any vehicles?",
    vehicleTypeLabel: "Select your vehicle type",
    loadAmountLabel: "Vehicle Load Amount",
    loadAmountLabel2: "in KG",
    loadAmountPlaceholder: "Enter your vehicle load amount",
    vehicleNumberLabel: "Vehicle Number",
    vehicleNumberPlaceholder: "Enter your vehicle number",
    truck: "Truck",
    car: "Car",
    motorcycle: "Motorcycle",
    escooter: "E-Scooter",
    bicycle: "Bicycle",
    drivingError: "Select whether you have a driving license or not !",
    vehicleError: "Select whether you have a vehicle or not!",
    successMessage: "Driving Information Saved Successfully !",
  },
  profile: {
    pageTitle: "Profile & Settings",
    contactMenuLable: "User Information",
    passwordMenuLable: "Change Password",
    logoutMenuLable: "Log Out",
    changeLanguageMenuLable: "Change Language",
    refer: "Refer",
    referNavTitle: "Referral Code",
    referTitle: "Here is your Referral Code",
    referSubtitle: "Please Share it with your Customers",
    referList: "Refer List",
    referModalTitle:
      "Are you sure you want to remove this customer from your refer?",
    yes: "Yes",
    no: "No",
  },
  refer: {
    pageTitle: "Referral Code",
    confirmBtnLabel: "Confirm",
    skipBtnLabel: "I don’t have any referral code",
    title: "Apply referral code & earn points",
    subTitle: "Collect referral codes from friends around you",
    codePlaceholder: "Enter a referral code here",
    referCodeErrorMessage: "Please enter a referral code!",
    confirmButtonTitle: "Confirm",
    skipButtonTitle: "I don't have any referral code",
  },
  changeLanguages: {
    pageTitle: "Select Language",
    btnLabel: "Save",
  },
  contact: {
    pageTitle: "User Information",
    nameLabel: "Name",
    namePlaceholder: "Enter your name",
    emailLabel: "Email",
    emailPlaceholder: "Enter your email",
    nidLabel: "NID",
    nidPlaceholder: "Enter NID number",
    btnLabel: "Save",
    successLabel: "Your profile Updated. successfully",
    currectEmail: "please enter current email address",
  },
  otpsend: {
    pageTitle: "Verify Number",
    btnLabel: "Send",
    message: "Tap to send authentication code to your mobile via SMS.",
    successLabel: "Your OTP has been sent to your phone",
    errorLabel: "Your OTP not create yet please try again",
  },
  otpsubmit: {
    pageTitle: "Verify Number",
    btnLabel: "Submit",
    title: "Verification Code",
    paragraph: "We have sent the verification code to Your Mobile Number",
    otpLabel: "OTP",
    otpPlaceholder: "Enter your OTP code",
    receiveYetLabel: "Didn’t recieve code?",
    againRequestLabel: "Request again",
    timerLabel: "Please wait",
    second: "second",
    successLabel: "Your OTP has been sent to your phone",
    errorLabel: "Your OTP not create yet please try again",
    tokenVarifiedMessage: "Your OTP has been verified",
    validationLabel: "Please submit your OTP",
  },
  changePassword: {
    pageTitle: "Change Password",
    btnLabel: "Submit",
    passwordLabel: "PASSWORD",
    passwordPlaceholder: "Enter your password",
    confirmPassLabel: "CONFIRM PASSWORD",
    confirmPassPlaceholder: "Enter your confirm password",
    successLabel: "Your password Updated.",
    errorLabel: "Your password Updated Failed",
    passNotMatchLabel: "Your password and confirm password not match.",
    changePassSuccessPara: "Your password has been Changed Successfully.",
    changePassContinueBtn: "Continue",
    backBtnLabel: "",
  },

  locationPicker: {
    pageTitle: "Search The Store Location",
    btnLabel: "CONFIRM",
    paragraph: `Move map to your business location to select it`,
  },
  location: {
    title: "Enable your Location",
    paragraph:
      "This app requires that location services are turned on your device and for this app. You must enabel them is Setting before using this app.",
    btnLabel: "Allow only while using the app",
  },

  pwaDownload: {
    header: "Download EkkBaz App",
    paragraph:
      "Download the app and order bulk from your supplier any time from now on.",
    cancelBtn: "Cancel",
    downloadBtn: "Download",
  },

  landing: {
    header1: "Apply for EkkHero",
    header2: "Start Working",
    header3: "Earn Money! ",
    header4: "Forgot Password",
    requestOtp: "Request OTP",
    login: "Log in",
    bangla: "বাংলা",
    english: "English",
    vietnamese: "Tiếng Việt",
    retail: "Retail",
    manufacture: "Supplier",
    hotline: "Hot line",
    phoneNumber: "Phone Number",
    phoneNumberPlaceHolder: "Enter Phone Number",
    fAbout: "Wholesale market at your fingertip. Buy Now, Pay Later!",
    location: "Location",
    singapore: "Singapore Office",
    bangladesh: "Bangladesh Office",
    bOfficeAddress: "13th Floor, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
    otherInfo: "Other info",
    partners: "Our Partners",
    news: "News",
    support: "Support",
    corporate: "Corporate",
    refundPolicy: "Refund Policy",
    termsCondition: "Terms Conditions",
    privacyPolicy: "Privacy Policy",
    contactUs: "Contact Us",
    messageUs: "Message Us",
    facebook: "Facebook",
    sales: "Sales",
    media: "Media",
    downloadBtn: "Download App",
    backers: "Our Backers",
    benefits: "Your Benefits",
    discover: "Latest EkkBaz News",
    benefitsOne: "24 hours on-time delivery",
    benefitsDisOne: "",
    benefitsTwo: "Best market prices",
    benefitsDisOneTwo: "",
    benefitsThree: "Largest SKUs assortments",
    benefitsDisOneThree: "",
    benefitsFore: "Financing supports for purchases",
    benefitsDisOneFore: "",
    seeMore: "See More",
    weAvailable: "We are Available",
  },
  others: {
    alert: "Alert",
    backButtonMessage:
      "Your cart have some items, if you leave this page your cart will be empty.",
    okBtnLabel: "OK",
    loader: "Please wait...",
    readMore: "read more...",
    seeMore: "See More",
  },
  autoLogOut: {
    pageTitle: "Automatic Logout",
    warningMsg: "Auto logout since this number was used in another Login",
    instrMsg: "Click the green button below to login again",
    btnLabel: "Log in",
  },
  navFooter: {
    home: "Home",
    task: "T",
    supplier: "Supplier",
    learning: "Learning",
    account: "Account",
  },
  learning: {
    loader: "Please wait...",
    notFound: "Not found",
    pageTitle: "Learning",
    learningDetailsTitle: "Learning Details",
    comments: "Comments",
    commentAs: "Write your comment here",
    likedWarning: "You cannot like more than once",
  },
  customerList: {
    pageTitle: "Customer List",
    searchPlaceHolder: "Search Your Customer",
    detailsPageTitle: "Customer Details",
    customerDetailsLabel: "Customer Information",
    businessTypeLabel: "Business Type",
    customerNameLabel: "Name",
    addressLabel: "Address",
    postalCodeLabel: "Postal Code",
    cityLabel: "City",
    acceptedCustomerLabel: "Accepted Customer",
    requestedCustomerLabel: "Request For Price",
    accept: "Accept",
    acceptConfirmation: "Are you sure you want to Accept this Customer?",
    km: "km",
    createCustomer: "Create New Business",
    customerNoHolder: "Enter Mobile Number",
    nextBtn: "Next",
    deleteDistributor: "Delete Customer",
    addCustomPrice: "Check in",
    warningMsg:
      "Are you sure you want to update this business contract status?",
    yes: "Yes",
    no: "No",
    successMsg: "Contract Status Updated Successfully!",
    customPriceMsg: "Are you sure want to custom price for this Customer?",
    inputPlaceholder: "Enter Custom Price",
    priceEmptyError: "Custom Price cannot be empty!",
    alreadySetPriceError: "This Product price already set for this Customer",
    cancelBtn: "Cancel",
    submit: "Submit",
    modalTitle: "Set Custom Product Price",
    productsSectionTitle: "Customized Price Products",
    updatePriceTitle: "Update Custom Price",
    deletePriceTitle: "Delete This Price Product",
    productDetailTitle: "Product Details",
    offerDetailsTitle: "Offer Details",
    description: "Are you sure you want to delete this custom price product?",
    notFound: "Not Found",
    loader: "Please wait",
    update: "Confirm",
    cancel: "Cancel",
    updateStatus: "Update Status",
    orderForecast: "Create Future Order",
    updateCloseDate: "Update Expected Closed Date",
    closeDate: "Expected Closed Date",
    closeDateSuccessMessage: "Contract Updated Successfully",
    createdFilterLabel: "Created by Me",
    assignedFilterLabel: "Assigned to Me",
  },
  newCustomer: {
    pageTitle: "Create New Business",
    mobileNo: "Mobile Number",
    customerNoHolder: "Business mobile number",
    customerName: "User Name",
    role: "Role",
    selectRole: "Select Role",
    customerNameHolder: "Enter User full name",
    businessPhoto: "Business Photo",
    uploadPhoto: "Upload Business/Store Photo",
    uploadPhotoTitle: "Upload Photo",
    uploadFromGallery: "Upload From Gallery",
    takePhoto: "Take Photo",
    businessName: "Business Name",
    businessNameHolder: "enter business/store name",
    address: "Address",
    addressHolder: "enter business/store address",
    city: "City",
    cityHolder: "enter city",
    postalCode: "Postal Code",
    postalCodeHolder: "enter postal code",
    selectBusiness: "Select Business Type",
    fmcg: "FMCG",
    nonFmcg: "NON-FMCG",
    nextBtn: "Next",
    completeBtn: "Complete",
    customerFindText: `Business is already found here, if you want you, can choose to add it to your customer list.`,
    addBusiness: "Add Business",
    addBusinessInfo: "Do you want to add this business as your customer?",
    noBtn: "No",
    alreayExist: "Business already exists!",
    reseller: "Reseller",
    businessNameWarning: "Please Add Business Name",
    distributorNameWarning: "Please Add User Name",
    businessCreationSuccess: "New Business Created Successfully",
    businessLocationWarning: "Please Add Business Location from Google Map",
  },
  checkIn: {
    pageTitle: "Check In",
    remark: "Remark",
    remarkPlaceholder: "Please write your comment here",
    imageTitle: "Visit Image Upload",
    checkOutBtnTitle: "Check Out",
    successTitle: "Great Job",
    checkInMessage: "Your Check In is successfully completed",
    checkOutMessage: "Your Check Out is successfully completed",
    nextBtnTitle: "Go for Next",
    imageSizePlaceholder: "Maximum file size support 2MB, JPG, JPEG, PNG",
    checkInSuccessMessage: "Checked In Successfully!",
    checkOutSuccessMessage: "Checked Out Successfully!",
    uploadImage: "Upload Image",
    imageUpload: "Image Upload",
    uploadFromGallery: "Upload From Gallery",
    takeYourImage: "Take Your Selfie",
  },
  createBusinessInfo: {
    pageTitle: "Contract Management Info",
    title: "Business Information",
    brandsLabel: "Brand's Name",
    brandsPlaceholder: "Enter Brands Name",
    skusLabel: "Total SKU",
    skusPlaceholder: "SKU amount",
    srsLabel: "Total SR",
    srsPlaceholder: "SR amount",
    driversLabel: "Total Driver",
    driversPlaceholder: "Driver amount",
    customersLabel: "Total Customer",
    customersPlaceholder: "Customer amount",
    salesLabel: "Monthly Sales Amount",
    salesPlaceholder: "Enter Sales Amount",
    fsLabel: "Monthly FS Amount Needed",
    fsPlaceholder: "Enter FS Amount Needed",
    pwFsLabel: "Probable Week for FS Need",
    pwFsPlaceholder: "Select week",
    operationLabel: "Business Operation Start Date/Year",
    operationExtraLabel: "Year",
    operationPlaceholder: "Select Year",
    aeLabel: "AE Feedback",
    aePlaceholder: "Enter AE Feedback",
    nextBtn: "Next",
    owner: "Owner",
    manager: "Manager",
    stuff: "Stuff",
    validationLabel: "Please fill up the address to move.",
    okBtnLabel: "OK",
    successMessage: "Business info created successfully",
    brandError: "Enter brands name",
    skuError: "Enter sku amount",
    srError: "Enter sr amount",
    driverError: "Enter driver amount",
    customerError: "Enter customer amount",
    salesError: "Enter sales amount",
    fsError: "Enter finance support Amount",
    operationError: "Select operations start year",
    feedbackError: "Enter your feedback",
    regionLabel: "District",
    areaLabel: "Sub District/Thana",
    regionError: "Select a region",
    areaError: "Select a area",
    areaPlaceholderWarning: "Please select a region first",
    pwFsError: "Please select a week",
    interestedAreaPageTitle: "Interested Area",
    interestedAreaQuestionTitle:
      "What is the interested area of this business?",
    software: "Software",
    financing: "Financing",
    usedSoftware: "Software Using",
    usedSoftwareError: "Please enter software name",
    usedSoftwarePlaceholder: "Enter using software name",
    usedSoftwareProblem: "Pain Point",
    usedSoftwareProblemError: "Please enter pain points",
    usedSoftwareProblemPlaceholder: "Enter pain points",
  },
  customerDetails: {
    AEUserLabel: "Account Executive",
    HSuserLabel: "Hub Staff",
    visitType: "Visit Type",
    createdAt: "Created at",
    createdBy: "Created by",
    updatedAt: "Updated at",
    updatedBy: "Updated by",
    businessCardTitle: "Business Information",
    contactCardTitle: "Contact Information",
    contractCardTitle: "Contract Information",
    visitCardTitle: "Visit Information",
    name: "Name",
    number: "Number",
    role: "Role",
    orderType: "Order Type",
    serviceType: "Service Type",
    revenueTier: "Revenue Tier",
    expectedRevenue: "Expected Monthly Revenue",
    expectedCloseDate: "Expected Close Date",
    status: "Status",
    checkIn: "Check in Time",
    checkOut: "Check out Time",
    remark: "Remark",
    location: "Location",
    image: "Image",
    image2: "Check Out Image",
  },
  createContact: {
    pageHeadline: "Contact Information",
    nameLabel: "Name",
    namePlaceholder: "Enter Name",
    numberLabel: "Number",
    numberPlaceholder: "Enter Number",
    roleError: "Select a role",
  },
  createContract: {
    pageHeadline: "Contract Information",
    orderTypeLabel: "Order Type",
    serviceTypeLabel: "Service Type",
    revenueTierLabel: "Revenue Tier",
    statusLabel: "Status",
    selectPlaceholder: "Select",
    monthlyRevenueLabel: "Expected Monthly Revenue",
    monthlyRevenuePlaceholder: "Enter expected monthly revenue",
    expectedCloseDateLabel: "Expected Closed Date",
    expectedCloseDatePlaceholder: "Select date",
    primaryOrder: "Primary",
    secondaryOrder: "Secondary",
    tertiaryOrder: "Tertiary",
    successMessage: "Contract created successfully",
    statusError: "Select a status",
    closeDateError: "Select a close date",
    monthlyRevenueError: "Enter monthly revenue amount",
    revenueTierError: "Select a revenue tier",
    serviceTypeError: "Select a service type",
    orderTypeError: "Select a order type",
  },
  orderForecast: {
    supplierLabel: "Supplier",
    supplierPlaceholder: "Select a Supplier",
    supplierError: "Please select a supplier",
    deliveryDateLabel: "Possible Date of Taking Financing Support",
    deliveryDatePlaceholder: "Select a date",
    deliveryDateError: "Please select a date",
    orderAmountLabel: "Order Amount",
    orderAmountPlaceholder: "Enter order amount",
    orderAmountError: "Please enter order amount",
    financeSupportLabel: "Finance Support",
    financeSupportPlaceholder: "Enter expected finance support",
    financeSupportError: "Please enter expected finance support",
    btnLabel: "Confirm",
    successMessage: "Future Order Created Successfully",
    selectSupplierPageTitle: "Supplier List",
    supplierSearchPlaceholder: "Search supplier",
  },
};
export default english;
