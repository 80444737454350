const bangla = {
  login: {
    contryLabel: "দেশ",
    contryPlaceHolder: "",
    phoneLable: "ফোন নম্বর",
    phonePlaceHolder: "মোবাইল নম্বর লিখুন",
    validationLabel: "আপনার বৈধ ফোন নম্বরটি ইংরেজিতে লিখুন",
    btnLabel: "পরবর্তী",
    tokenSuccessLabel: " সফলভাবে ওটিপি পাঠানো হয়েছে",
    tokenSuccessFailed: "ওটিপিটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
    langChange: "ভাষা পরিবর্তন করুন",
    termsAllow: "লগ-ইন করার সময় শর্তাবলী অনুমতি দিন!",
  },
  password: {
    passwordLabel: "পাসওয়ার্ড",
    passwordPlaceHolder: "পাসওয়ার্ড লিখুন",
    forgetPass: "পাসওয়ার্ড ভুলে গিয়েছেন",
    backWord: "পেছনে যান",
    btnLabel: "প্রবেশ করুন",
    otpSendLabel: "আপনার মোবাইলে ওটিপি পাঠানো হয়েছে,",
    passwordNotMatch: "পাসওয়ার্ড মেলেনি",
  },
  changePass: {
    passwordLabel: "নতুন পাসওয়ার্ড",
    passwordPlaceHolder: "*******************",
    confirmPassLabel: "পাসওয়ার্ড নিশ্চিত করুন",
    confirmPassPlaceHolder: "*******************",
    passNotMatchLabel: "পাসওয়ার্ড মেলে না",
    btnLabel: "পাসওয়ার্ড নিশ্চিত করুন",
    successLabel: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে",
    errorLabel: "আপনার পাসওয়ার্ড পরিবর্তন ব্যর্থ হয়েছে",
    lengthErrorLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
    notMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
    backBtnLabel: "মেইন সাইটে ফিরে যান",
  },
  token: {
    tokenLabel: "ওটিপি",
    tokenPlaceHolder: "আপনার ওটিপি লিখুন",
    tokenResend: "পুনরায় ওটিপি পাঠাও",
    notGet: "ওটিপি পাননি?",
    tokenSuccessLabel:
      " আপনার মোবাইলে ওটিপি পাঠানো হয়েছে, পরবর্তী ধাপে যেতে এটি লিখুন",
    tokenErrorLabel: "ওটিপিটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
    timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
    second: "সেকেন্ড",
    btnLabel: "ওটিপি নিশ্চিত করুন",
    tokenNotMatch: "আপনার ওটিপি কোড ভুল",
    tokenVarified: "আপনার ওটিপি যাচাই করা হয়েছে",
    tokenNotWrite: "আপনার সঠিক ওটিপি লিখুন",
  },
  registration: {
    nameLabel: "পূর্ণ নাম",
    namePlaceholder: "পূূর্ণ নাম লিখুন",
    createProfile: "আপনার প্রোফাইল তৈরি করুন",
    helpUs: "দয়া করে সঠিক তথ্য সরবরাহ করে আমাদের সাহায্য করুন",
    emailLabel: "ইমেইল ",
    emailPlaceholder: "ইমেইল লিখুন",
    passwordLabel: "পাসওয়াার্ড",
    passwordPlaceholder: "পাসওয়ার্ড লিখুন",
    confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
    confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
    validationLabel: "ইনপুট ক্ষেত্র ৪ অক্ষরের হতে হবে",
    passNotMatch: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
    btnLabel: "পরবর্তী",
    successMessage: "প্রোফাইল সফলভাবে তৈরি করা হয়েছে",
    errorMessage: "বৈধ ইনপুট চেক করুন",
    passLengthValidLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
    dobLabel: "জন্ম তারিখ",
    day: "দিন",
    month: "মাস",
    year: "বছর",
    genderLabel: "লিঙ্গ",
    male: "পুরুষ",
    female: "নারী",
    others: "অন্যান্য",
    passwordLength: "আপনার পাসওয়ার্ডটি",
    strong: "শক্তিশালী",
    medium: "মাধ্যম",
    weak: "দুর্বল",
  },
  letNav: {
    homeMenuLabel: "হোম",
    taskMenuLabel: "কাজের তালিকা",
    analyticsMenuLabel: "কনট্রাক্ট ম্যানেজমেন্ট",
    orderMenuLabel: "অর্ডার তালিকা",
    mySupplierMenuLabel: "আমার সরবরাহকারী",
    businessProfileMenuLabel: "ব্যবসায়িক প্রোফাইল সেটিংস",
    phoneCall: "কল টু অর্ডার",
    referMenuLabel: "রেফারেল",
  },
  jobSelect: {
    titlePart1: "আমাদের EkkHero কমিউনিটিতে স্বাগতম। ",
    ekkHero: "EkkHero",
    titlePart2: " আপনি আপনার টাস্ক সম্পূর্ণ করতে পারেন এবং ",
    earnMoney: "অর্থ উপার্জন করতে পারেন।",
    sales: "সেলস",
    delivery: "ডেলিভারি",
    others: "অন্যান্য",
    btnLabel: "পরবর্তী",
    navLabel: "এক বা একাধিক নির্বাচন করুন",
  },
  home: {
    showMoreLabel: "আরো দেখুন",
    brandLabel: "ব্র্যান্ড",
    registerBtnLabel: "নিবন্ধন",
    updateBtnLabel: "তথ্য আপডেট করুন",
    offerLabel: "অফার পণ্য",
    mySuppliersLabel: "আমার সরবরাহকারী",
    nearSuppliersLabel: "কাছাকাছি সরবরাহকারী",
    recommendentLabel: "শুধু আপনার জন্য",
    searchLabel: "পণ্য সার্চ করুন",
    todayPurchaseLabel: "মোট কাস্টমার",
    totalOrderLabel: "ধরণের পণ্য কিনেছেন",
    totalOrder: "টি অর্ডারে",
    skusLabel: "এসকেইউ",
    tillNow: "আজকের পরিদর্শন",
    orderDue: "অর্ডার বাকি",
    brandProductPageTitle: "সকল ব্র্যান্ড",
    offerProductPageTitle: "মূল্যছাড় সকল পণ্য",
    offerProductNotFound: "দুঃখিত, আপনার জন্য কোন অফার পাওয়া যায়নি.",
    mySupplierNotFound:
      "আপনার কোনো সরবরাহকারী নেই। এখানে আপনার সরবরাহকারীদের যোগ করতে পণ্য কিনুন।",
    supplierNotFound:
      "দুঃখিত, আপনার এলাকায় কোন সরবরাহকারী পাওয়া যায়নি, আমরা খুব শীঘ্রই আপনার এলাকায় কিছু সরবরাহকারী বরাদ্দ করব।",
    brandSeller: "সরবরাহকারী",
    allBrandSeller: "সকল সরবরাহকারী",
    km: "কিমি",
    step1Title: "ধাপ 1 - EkkHero হিসাবে নিবন্ধন করুন",
    step1Content:
      "EkkHero হিসাবে অনুমোদিত হতে আপনার তথ্য জমা দিন। আপনি নিবন্ধন না করা পর্যন্ত আপনি কোনো চাকরিতে আবেদন করতে পারবেন না।",
    step2Title: "ধাপ 2 - আপনার আইডি যাচাইকরণের জন্য অপেক্ষা করুন",
    step2Content:
      "যখন আপনার নিবন্ধন শেষ করুন আপনার আইডি যাচাইকরণের জন্য অপেক্ষা করুন। আপনার আইডি ভেরিফিকেশন হয়ে গেলে রেজিস্টার বোতাম এবং প্রোফাইল সবুজ হয়ে যাবে।",
    step3Title: "ধাপ 3 - টাস্ক/চাকরির জন্য আবেদন করুন",
    step3Content: "টাস্ক/চাকরির তালিকা দেখুন এবং তাদের জন্য আবেদন করুন।",
    recommendentionLabel: "শিক্ষণীয় বিষয়",
    applyNowBtnLabel: "এখনই আবেদন করুন",
    clickInfoLabel: "এই কাজটি করার জন্য “এখনই আবেদন করুন” বাটনে ক্লিক করুন।",
    jobDetailsTitle: "চাকরি/কাজের বিবরণ",
  },
  workVerify: {
    workVerifyTitle: "কাজের ধরন ও বেতন",
    workTypeLabel: "কাজের ধরন",
    workTypeOption1: "পার্ট টাইম",
    workTypeOption2: "ফুল টাইম",
    previousWorkExperienceLabel: "পূর্ববর্তী কাজের অভিজ্ঞতা",
    previousWorkExperienceOption1: "হ্যাঁ",
    previousWorkExperienceOption2: "না",
    monthlySalaryLabel: "মাসিক বেতন",
    monthlySalaryLabel2: "একবাজের কাছ থেকে প্রত্যাশিত",
    monthlySalaryPlaceholder: "আপনার প্রত্যাশিত মাসিক বেতন লিখুন",
    workAreaLabel: "জেলা",
    workAreaLabel3: "উপজেলা/থানা",
    workAreaLabel2: "আগ্রহী",
    workAreaPlaceholder: "আপনার আগ্রহী কর্মক্ষেত্র নির্বাচন করুন",
    workAreaPlaceholderWarning: "অনুগ্রহ করে প্রথমে একটি জেলা নির্বাচন করুন",
    academicQualificationLabel: "শিক্ষাগত যোগ্যতা",
    academicQualificationLabel2:
      "অনুগ্রহ করে আপনার একাডেমিক শিক্ষার স্তর নির্বাচন করুন",
    academiOption1: "প্রথম থেকে পঞ্চম শ্রেণি (পিএসসি)",
    academiOption2: "প্রথম থেকে অষ্টম শ্রেণি (জেএসসি)",
    academiOption3: "প্রথম থেকে দশম শ্রেণি (এসএসসি)",
    academiOption4: "প্রথম থেকে দ্বাদশ শ্রেণি (এইচএসসি)",
    academiOption5: "অন্যান্য",
    addFileLabel: "আপনার সিভি যোগ করুন",
    addFileLabel2: "অনুগ্রহ করে একটি পিডিএফ ফাইল আপলোড করুন",
    addFilePlaceholder: "ফাইল ব্রাউজ করুন",
    successMessage: "কাজ এবং বেতন সফলভাবে সংরক্ষিত হয়েছে!",
    workError: "অনুগ্রহ করে কাজের ধরন সিলেক্ট করুণ !",
    experienceError: "অনুগ্রহ করে পূর্ববর্তী কাজের অভিজ্ঞতা সিলেক্ট করুণ !",
    districtError: "অনুগ্রহ করে কাজ করার জন্য আগ্রহী জেলা সিলেক্ট করুণ !",
    upazillaError:
      "অনুগ্রহ করে কাজ করার জন্য আগ্রহী উপজেলা/থানা সিলেক্ট করুণ !",
  },
  drivingVerify: {
    drivingTitle: "ড্রাইভিং ও যানবাহন",
    haveDrivingLabel: "আপনার কি ড্রাইভিং লাইসেন্স আছে?",
    licenceNumberLabel: "ড্রাইভিং লাইসেন্স নম্বর",
    licenceNumberPlaceholder: "আপনার ড্রাইভিং লাইসেন্স নম্বর লিখুন",
    haveVehicleLabel: "আপনার কি কোনো গাড়ি আছে?",
    vehicleTypeLabel: "আপনার গাড়ির ধরন নির্বাচন করুন",
    loadAmountLabel: "গাড়ির লোডের পরিমাণ",
    loadAmountLabel2: "কেজিতে",
    loadAmountPlaceholder: "আপনার গাড়ির লোড পরিমাণ লিখুন",
    vehicleNumberLabel: "গাড়ির নম্বর",
    vehicleNumberPlaceholder: "আপনার গাড়ির নম্বর লিখুন",
    truck: "ট্রাক",
    car: "কার",
    motorcycle: "মোটরসাইকেল",
    escooter: "ই-স্কুটার",
    bicycle: "বাইসাইকেল",
    drivingError: "আপনার ড্রাইভিং লাইসেন্স আছে কি না তা নির্বাচন করুন !",
    vehicleError: "আপনার গাড়ি আছে কি না তা নির্বাচন করুন !",
    successMessage: "ড্রাইভিং তথ্য সফলভাবে সংরক্ষিত হয়েছে !",
  },
  skillVerify: {
    skillVerifyTitle: "শিল্প ও দক্ষতা",
    industryLabel: "কর্মক্ষম শিল্প",
    industryLabel2: "অনুগ্রহ করে আপনার কাজের শিল্প নির্বাচন করুন",
    food: "খাদ্য",
    fashion: "ফ্যাশন",
    pharmacy: "ফার্মেসি",
    marketing: "বিপণন",
    electronics: "ইলেকট্রনিকস",
    digitalServices: "ডিজিটাল সেবা",
    it: "আইটি",
    design: "নকশা",
    banking: "ব্যাংকিং",
    insurance: "বীমা",
    cosmetics: "প্রসাধনী",
    logistics: "লজিস্টিকস",
    skillLabel: "দক্ষতা",
    skillLabel2: "অনুগ্রহ করে আপনার সেরা দক্ষতা নির্বাচন করুন",
    driving: "শক্তিশালী ড্রাইভিং রেকর্ড",
    navigation: "নেভিগেশন",
    customerService: "গ্রাহক সেবা",
    vehicle: "যানবাহন রক্ষণাবেক্ষণ জ্ঞান",
    fitness: "শারীরিক ফিটনেস এবং শক্তি",
    problemSolving: "সমস্যা সমাধান",
    traffic: "ট্রাফিক আইন সম্পর্কে জ্ঞান",
    work: "কার্যকরভাবে একা কাজ করা",
    successMessage: "শিল্প ও দক্ষতা সফলভাবে সংরক্ষিত হয়েছে !",
  },
  profile: {
    pageTitle: "প্রোফাইল এবং সেটিংস",
    contactMenuLable: "ব্যবহারকারীর তথ্য",
    passwordMenuLable: "পাসওয়ার্ড পরিবর্তন করুন",
    logoutMenuLable: "লগ আউট",
    changeLanguageMenuLable: "ভাষা পরিবর্তন করুন",
    refer: "রেফার",
    referNavTitle: "রেফারেল কোড",
    referTitle: "এখানে আপনার রেফারেল কোড",
    referSubtitle: "দয়া করে এটি আপনার গ্রাহকদের সাথে শেয়ার করুন",
    referList: "রেফার তালিকা",
    referModalTitle:
      "আপনি কি নিশ্চিত যে আপনি এই গ্রাহককে আপনার রেফারেন্স থেকে অপসারণ করতে চান?",
    yes: "হ্যাঁ",
    no: "না",
  },
  addressVerify: {
    nidPlaceholder1: "এনআইডি - সামনের দিক",
    nidPlaceholder2: "এনআইডি - পিছনের দিক",
    nidSuccessMessage: "NID সফলভাবে আপলোড হয়েছে !",
    nidPlaceholder3: "এখানে আপনার ছবি আপলোড করুন, অথবা ব্রাউজ করুন",
    nidPlaceholder4: "সর্বাধিক ফাইল আকার 2 MB JPG, JPEG, PNG বা PDF",
    mainTitle: "অ্যাকাউন্ট যাচাই",
    addressTitlePart1: "দয়া করে আপনার ",
    addressTitlePart2: "সঠিক তথ্য",
    presentAddressLabel: "বর্তমান ঠিকানা",
    presentAddressPlaceholder: "আপনার সম্পূর্ণ ঠিকানা লিখুন",
    presentCityLabel: "বর্তমান শহর",
    presentCityPlaceholder: "শহর",
    postalCodeLabel: "পোস্টাল কোড",
    postalCodePlaceholder: "পোস্টাল কোড",
    nameLabel: "নাম",
    namePlaceholder: "আপনার পিতা/মাতা/ভাইয়ের নাম লিখুন",
    numberLabel: "নম্বর",
    numberPlaceholder: "আপনার পিতা/মাতা/ভাইয়ের নম্বর লিখুন",
    extraLabel: "জরুরী যোগাযোগের জন্য",
    cancel: "আমি এই তথ্য পরে দেব",
    successMessage: "ঠিকানা সফলভাবে সংরক্ষণ করা হয়েছে !",
    gaurdianNumberError: "অনুগ্রহ করে অভিভাবকের নম্বর লিখুন !",
    postalCodeError: "অনুগ্রহ করে পোস্টাল কোড লিখুন !",
    cityError: "অনুগ্রহ করে শহরের নাম লিখুন !",
    presentAddressError: "অনুগ্রহ করে বর্তমান ঠিকানা লিখুন !",
  },
  contact: {
    pageTitle: "ব্যবহারকারীর তথ্য",
    nameLabel: "নাম",
    namePlaceholder: "আপনার নাম লিখুন",
    emailLabel: "ইমেইল",
    emailPlaceholder: "ইমেইল লিখুন",
    nidLabel: "এনআইডি",
    nidPlaceholder: "এনআইডি লিখুন",
    btnLabel: "সেভ করুন",
    successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
    currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
  },
  otpsend: {
    pageTitle: "নম্বর যাচাই করুন",
    btnLabel: "পাঠান",
    message:
      "খুদেবার্তা এর মাধ্যমে আপনার মোবাইলে প্রমাণীকরণ কোড পাঠাতে ট্যাপ করুন।",
    successLabel: "আপনার ফোনে আপনার ওটিপি পাঠানো হয়েছে",
    errorLabel: "আপনার ওটিপি এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
  },
  otpsubmit: {
    pageTitle: "নম্বর যাচাই করুন",
    btnLabel: "জমা দিন",
    title: "যাচাইকরণ কোড",
    paragraph: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
    otpLabel: "ওটিপি",
    otpPlaceholder: "আপনার ওটিপি কোড লিখুন",
    receiveYetLabel: "কোড পাননি?",
    againRequestLabel: "আবার অনুরোধ করুন",
    timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
    second: "সেকেন্ড",
    successLabel: "আপনার ফোনে আপনার ওটিপি পাঠানো হয়েছে",
    errorLabel: "আপনার ওটিপি এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
    tokenVarifiedMessage: "আপনার ওটিপি যাচাই করা হয়েছে",
    validationLabel: "আপনার ওটিপি কোড জমা দিন",
  },
  changePassword: {
    pageTitle: "পাসওয়ার্ড পরিবর্তন",
    btnLabel: "জমা দিন",
    passwordLabel: "পাসওয়াার্ড",
    passwordPlaceholder: "পাসওয়ার্ড লিখুন",
    confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
    confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
    successLabel: "আপনার পাসওয়ার্ড আপডেট করা হয়েছে.",
    errorLabel: "আপনার পাসওয়ার্ড আপডেট ব্যর্থ হয়েছে",
    passNotMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
    changePassSuccessPara: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন করা হয়েছে.",
    changePassContinueBtn: "পরবর্তী",
  },
  refer: {
    pageTitle: "রেফারেল কোড",
    confirmBtnLabel: "নিশ্চিত",
    skipBtnLabel: "আমার কাছে কোনও রেফারেল কোড নেই",
    title: "রেফারেল কোড প্রদান করুন এবং পয়েন্ট উপার্জন করুন",
    subTitle: "আপনার বন্ধুদের কাছ থেকে রেফারেল কোড সংগ্রহ করুন",
    codePlaceholder: "এখানে একটি রেফারেল কোড লিখুন",
    referCodeErrorMessage: "অনুগ্রহ করে একটি রেফারেল কোড লিখুন!",
    confirmButtonTitle: "নিশ্চিত করুন",
    skipButtonTitle: "আমার কোনো রেফারেল কোড নেই",
  },
  changeLanguages: {
    pageTitle: "ভাষা নির্বাচন করুন",
    btnLabel: "সংরক্ষণ করুন",
  },

  locationPicker: {
    pageTitle: "দোকান অবস্থান অনুসন্ধান",
    btnLabel: "নিশ্চিত করুন",
    paragraph: `মেপ থেকে আপনার ব্যবসার অবস্থান নির্বাচন করুন।`,
  },
  location: {
    title: "আপনার অবস্থান সক্রিয় করুন",
    paragraph:
      "এই অ্যাপটির জন্য আপনার ডিভাইস এবং এই অ্যাপের জন্য লোকেশন পরিষেবা চালু থাকা প্রয়োজন। এই অ্যাপটি ব্যবহার করার আগে আপনাকে অবশ্যই সেগুলিকে সক্রিয় করতে হবে।",
    btnLabel: "শুধুমাত্র অ্যাপ ব্যবহার করার সময় অনুমতি দিন",
  },

  pwaDownload: {
    header: "EkkBaz অ্যাপ ডাউনলোড করুন",
    paragraph:
      "অ্যাপটি ডাউনলোড করুন এবং এখন থেকে যেকোনো সময় আপনার সরবরাহকারীর কাছ থেকে বাল্ক অর্ডার করুন।",
    cancelBtn: "বাতিল করুন",
    downloadBtn: "ডাউনলোড করুন",
  },

  landing: {
    header1: "EkkHero এর জন্য আবেদন করুন",
    header2: "কাজ শুরু করুন",
    header3: "অর্থ উপার্জন করুন!",
    header4: "পাসওয়ার্ড ভুলে গেছেন",
    requestOtp: "ওটিপির জন্য অনুরোধ করুন",
    login: "প্রবেশ করুন",
    bangla: "বাংলা",
    english: "English",
    vietnamese: "Tiếng Việt",
    retail: "দোকানদার",
    manufacture: "সাপ্লায়ার",
    hotline: "হট লাইন",
    phoneNumber: "ফোন নম্বর",
    phoneNumberPlaceHolder: "ফোন নম্বর লিখুন",
    fAbout: "পাইকারি বাজার এখন হাতের মুঠোয়। বাকিতে কিনুন, পরে পরিশোধ করুন।",
    location: "অবস্থান",
    singapore: "সিঙ্গাপুর অফিস",
    bangladesh: "বাংলাদেশ অফিস",
    bOfficeAddress: "১৩ তলা, রাজ্জাক প্লাজা, মগ বাজার মোড়, ঢাকা-১২০৫",
    otherInfo: "অন্যান্য তথ্য",
    partners: "আমাদের অংশীদার",
    news: "খবর",
    support: "সাপোর্ট",
    corporate: "কর্পোরেট",
    refundPolicy: "রিফান্ড পলিসি",
    termsCondition: "টার্মস & কন্ডিশনস",
    privacyPolicy: "প্রাইভেসী পলিসি",
    contactUs: "যোগাযোগ করুন",
    messageUs: "মেসেজ করুন",
    facebook: "ফেসবুক",
    sales: "বিক্রয়",
    media: "মিডিয়া",
    downloadBtn: "ডাউনলোড করুন",
    backers: "আমাদের ব্যাকার্স",
    benefits: "আপনার সুবিধা",
    discover: "সর্বশেষ একবাজ খবর",
    benefitsOne: "২৪ ঘন্টা অন-টাইম ডেলিভারি",
    benefitsDisOne: "",
    benefitsTwo: "সেরা বাজার মূল্য",
    benefitsDisOneTwo: "",
    benefitsThree: "বৃহত্তম SKU ভাণ্ডার",
    benefitsDisOneThree: "",
    benefitsFore: "ক্রয়ের জন্য অর্থায়ন সমর্থন",
    benefitsDisOneFore: "",
    seeMore: "আরো দেখুন",
    weAvailable: "আমরা আছি",
  },
  others: {
    alert: "সতর্কতা",
    backButtonMessage:
      "আপনার কার্টে কিছু আইটেম আছে, আপনি যদি এই পৃষ্ঠাটি ছেড়ে যান তবে আপনার কার্ট খালি হয়ে যাবে।",
    okBtnLabel: "ঠিক আছে",
    loader: "অনুগ্রহপূর্বক অপেক্ষা করুন ...",
    readMore: "আরও পড়ুন...",
    seeMore: "আরও দেখুন",
  },
  autoLogOut: {
    pageTitle: "স্বয়ংক্রিয় লগআউট",
    warningMsg:
      "স্বয়ংক্রিয় লগআউট যেহেতু এই নম্বরটি অন্য লগইনে ব্যবহৃত হয়েছিল৷",
    instrMsg: "আবার লগইন করতে নিচের সবুজ বোতামে ক্লিক করুন",
    btnLabel: "লগইন",
  },
  navFooter: {
    home: "হোম",
    order: "অর্ডার",
    supplier: "সাপ্লাইয়ার",
    learning: "শিক্ষা",
    account: "অ্যাকাউন্ট",
  },
  learning: {
    loader: "অনুগ্রহ করে অপেক্ষা করুন...",
    notFound: "পাওয়া যায়নি",
    pageTitle: "শিক্ষণীয় বিষয়",
    learningDetailsTitle: "জ্ঞানের বিবরণ",
    comments: "মন্তব্য",
    commentAs: "এখানে আপনার মন্তব্য লিখুন",
    likedWarning: "একবারের বেশি লাইক দিতে পারবেন না",
  },
  customerList: {
    pageTitle: "কাস্টমার তালিকা",
    searchPlaceHolder: "আপনার কাস্টমারের নাম লিখুন",
    detailsPageTitle: "কাস্টমারের বিবরণ",
    customerDetailsLabel: "কাস্টমার তথ্য",
    businessTypeLabel: "ব্যবসার ধরণ",
    customerNameLabel: "নাম",
    addressLabel: "ঠিকানা",
    postalCodeLabel: "পোস্টাল কোড",
    cityLabel: "শহর",
    acceptedCustomerLabel: "গৃহীত কাস্টমার",
    requestedCustomerLabel: "মূল্যের জন্য অনুরোধ",
    accept: "গ্রহণ করুন",
    acceptConfirmation: "আপনি কি নিশ্চিত আপনি এই কাস্টমারকে গ্রহণ করতে চান?",
    km: "কি.মি.",
    createCustomer: "নতুন ব্যবসা তৈরি করুন",
    customerNoHolder: "মোবাইল নম্বর লিখুন",
    nextBtn: "পরবর্তী",
    deleteDistributor: "কাস্টমার মুছুন",
    addCustomPrice: "চেক ইন",
    warningMsg:
      "আপনি কি নিশ্চিত যে আপনি এই ব্যবসার কনট্রাক্ট স্ট্যাটাস আপডেট করতে চান?",
    yes: "হ্যাঁ",
    no: "না",
    successMsg: "কনট্রাক্ট স্ট্যাটাস সফলভাবে আপডেট হয়েছে!",
    customPriceMsg: "এই কাস্টমারের জন্য কাস্টম মূল্য সেট করতে কি আপনি নিশ্চিত?",
    inputPlaceholder: "স্পেশাল মূল্য লিখুন",
    priceEmptyError: "স্পেশাল মূল্য খালি রাখা যাবে না!",
    alreadySetPriceError:
      "এই পণ্যের মূল্যটি ইতিমধ্যে এই কাস্টমারের জন্য নির্ধারণ করা হয়েছে।",
    cancelBtn: "বাতিল",
    submit: "জমা দিন",
    modalTitle: "স্পেশাল মূল্য নির্ধারণ করুন",
    productsSectionTitle: "স্পেশাল মূল্যর পণ্য তালিকা",
    updatePriceTitle: "স্পেশাল মূল্য পরিবর্তন করুন",
    deletePriceTitle: "স্পেশাল মূল্য থেকে পণ্য মুছুন",
    productDetailTitle: "পণ্যের বিবরণ",
    offerDetailsTitle: "অফার বিবরণ",
    description: "আপনি কি নিশ্চিত যে আপনি এই স্পেশাল মূল্যর পণ্যটি মুছতে চান?",
    notFound: "পাওয়া যায়নি",
    loader: "অনুগ্রহ করে অপেক্ষা করুন",
    update: "নিশ্চিত",
    cancel: "বাতিল",
    updateStatus: "স্ট্যাটাস আপডেট করুন",
    orderForecast: "ভবিষ্যতের অর্ডার তৈরি করুন",
    updateCloseDate: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ আপডেট করুন",
    closeDate: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ",
    closeDateSuccessMessage: "কনট্রাক্ট সফলভাবে আপডেট হয়েছে",
    createdFilterLabel: "আমার তৈরি",
    assignedFilterLabel: "নিযুক্ত করা হয়েছে",
  },
  newCustomer: {
    pageTitle: "নতুন ব্যবসা তৈরি করুন",
    mobileNo: "মোবাইল নম্বর",
    customerNoHolder: "ব্যবসায়িক মোবাইল নম্বর",
    customerName: "ব্যবহারকারীর নাম",
    role: "রোল",
    selectRole: "রোল নির্বাচন করুন",
    customerNameHolder: "ব্যবহারকারীর পূর্ণ নাম লিখুন",
    businessPhoto: "ব্যবসায়িক ছবি",
    uploadPhoto: "ব্যবসা/স্টোরের ছবি আপলোড করুন",
    uploadPhotoTitle: "ছবি আপলোড",
    uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
    takePhoto: "ছবি তোল",
    businessName: "ব্যবসার নাম",
    businessNameHolder: "ব্যবসা/স্টোরের নাম লিখুন",
    address: "ঠিকানা",
    addressHolder: "ব্যবসা/স্টোরের ঠিকানা লিখুন",
    city: "শহর",
    cityHolder: "শহরের নাম লিখুন",
    postalCode: "পোস্ট কোড",
    postalCodeHolder: "পোস্ট কোড লিখুন",
    selectBusiness: "ব্যবসার ধরন নির্বাচন করুন",
    fmcg: "এফএমসিজি",
    nonFmcg: "নন-এফএমসিজি",
    nextBtn: "পরবর্তী",
    completeBtn: "সম্পূর্ণ",
    customerFindText: `ব্যবসা ইতিমধ্যেই এখানে পাওয়া গেছে, আপনি চাইলে এটিকে আপনার কাস্টমার তালিকাতে যোগ করতে পারেন।`,
    addBusiness: "ব্যবসা যোগ করুন",
    addBusinessInfo: "আপনি কি আপনার কাস্টমার হিসাবে এই ব্যবসা যোগ করতে চান?",
    noBtn: "না",
    alreayExist: "ব্যবসা ইতিমধ্যে বিদ্যমান!",
    reseller: "রিসেলার",
    businessNameWarning: "অনুগ্রহ করে ব্যবসার নাম যোগ করুন",
    distributorNameWarning: "অনুগ্রহ করে ব্যবসা/স্টোরের নাম যোগ করুন",
    businessCreationSuccess: "নতুন ব্যবসা সফলভাবে তৈরি করা হয়েছে",
    businessLocationWarning: "গুগল ম্যাপ থেকে ব্যবসার অবস্থান যোগ করুন",
  },
  checkIn: {
    pageTitle: "চেক ইন",
    remark: "মন্তব্য",
    remarkPlaceholder: "দয়া করে আপনার মন্তব্য লিখুন",
    imageTitle: "ভিজিটের ছবি আপলোড",
    checkOutBtnTitle: "চেক আউট",
    successTitle: "দারূন কাজ",
    checkInMessage: "আপনার চেক ইন সফলভাবে সম্পন্ন হয়েছে",
    checkOutMessage: "আপনার চেক আউট সফলভাবে সম্পন্ন হয়েছে",
    nextBtnTitle: "পরবর্তীতে যান",
    imageSizePlaceholder: "সর্বাধিক ফাইল আকার 2 MB JPG, JPEG, PNG",
    checkInSuccessMessage: "সফলভাবে চেক ইন হয়েছে!",
    checkOutSuccessMessage: "সফলভাবে চেক আউট হয়েছে!",
    uploadImage: "ইমেজ আপলোড করুন",
    imageUpload: "ইমেজ আপলোড",
    uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
    takeYourImage: "আপনার ছবি তুলুন",
  },
  createBusinessInfo: {
    pageTitle: "কনট্রাক্ট পরিচালনা তথ্য",
    title: "ব্যবসার তথ্য",
    brandsLabel: "ব্র্যান্ডের নাম",
    brandsPlaceholder: "যার যার সাথে ডিস্ট্রিবিউটর ব্যবসা করেন",
    skusLabel: "মোট এসকিইউ",
    skusPlaceholder: "এসকিইউ পরিমাণ",
    srsLabel: "মোট এসআর",
    srsPlaceholder: "এসআর পরিমাণ",
    driversLabel: "মোট ডিএসআর",
    driversPlaceholder: "ডিএসআর পরিমাণ",
    customersLabel: "মোট কাস্টমার",
    customersPlaceholder: "কাস্টমার পরিমাণ",
    salesLabel: "মাসিক বিক্রয় পরিমাণ",
    salesPlaceholder: "বিক্রয় পরিমাণ লিখুন",
    fsLabel: "মাসিক আর্থিক সহায়তা প্রয়োজন",
    fsPlaceholder: "আর্থিক সহায়তার পরিমাণ লিখুন",
    pwFsLabel: "আর্থিক সহায়তা প্রয়োজনের সম্ভাব্য সপ্তাহ",
    pwFsPlaceholder: "সপ্তাহ নির্বাচন করুন",
    operationLabel: "ব্যবসার অপারেশন শুরুর তারিখ/বছর",
    operationExtraLabel: "বছর",
    operationPlaceholder: "বছর নির্বাচন করুন",
    aeLabel: "এ ই মতামত",
    aePlaceholder: "এ ই মতামত লিখুন",
    nextBtn: "পরবর্তী",
    owner: "মালিক",
    manager: "ম্যানেজার",
    stuff: "স্টাফ",
    validationLabel: "অনুগ্রহ করে ঠিকানাটি পূরণ করুন",
    okBtnLabel: "ঠিক আছে",
    successMessage: "ব্যবসা তথ্য সফলভাবে তৈরি হয়েছে",
    brandError: "ব্র্যান্ডের নাম লিখুন",
    skuError: "এসকিউ পরিমাণ লিখুন",
    srError: "এসআর পরিমাণ লিখুন",
    driverError: "ডিএসআর পরিমাণ লিখুন",
    customerError: "গ্রাহক পরিমাণ লিখুন",
    salesError: "বিক্রয় পরিমাণ লিখুন",
    fsError: "আর্থিক সাপোর্ট পরিমাণ লিখুন",
    operationError: "অপারেশন শুরুর বছর নির্বাচন করুন",
    feedbackError: "আপনার মতামত লিখুন",
    regionLabel: "জেলা",
    areaLabel: "উপজেলা/থানা",
    regionError: "একটি অঞ্চল নির্বাচন করুন",
    areaError: "একটি এলাকা নির্বাচন করুন",
    areaPlaceholderWarning: "অনুগ্রহ করে প্রথমে একটি অঞ্চল নির্বাচন করুন",
    pwFsError: "একটি সপ্তাহ নির্বাচন করুন",
    interestedAreaPageTitle: "আগ্রহী ক্ষেত্র",
    interestedAreaQuestionTitle: "এই ব্যবসার আগ্রহ কোন ধরনের ক্ষেত্রে?",
    software: "সফটওয়্যার",
    financing: "আর্থিক",
    usedSoftware: "ব্যবহৃত সফটওয়্যার",
    usedSoftwareError: "দয়া করে সফটওয়্যার নাম দিন",
    usedSoftwarePlaceholder: "ব্যবহৃত সফটওয়্যার নাম দিন",
    usedSoftwareProblem: "সম্মুখিত সমস্যা",
    usedSoftwareProblemError: "দয়া করে সম্মুখিত সমস্যা দিন",
    usedSoftwareProblemPlaceholder: "সম্মুখিত সমস্যা দিন",
  },
  customerDetails: {
    AEUserLabel: "অ্যাকাউন্ট এক্সিকিউটিভ",
    HSuserLabel: "হাব স্টাফ",
    visitType: "ভিজিটের ধরন",
    createdAt: "তৈরির সময়",
    createdBy: "তৈরি করেছে",
    updatedAt: "আপডেটের সময়",
    updatedBy: "আপডেট করেছে",
    businessCardTitle: "ব্যবসার তথ্য",
    contactCardTitle: "যোগাযোগের তথ্য",
    contractCardTitle: "চুক্তির তথ্য",
    visitCardTitle: "ভিজিটের তথ্য",
    name: "নাম",
    number: "নম্বর",
    role: "রোল",
    orderType: "অর্ডার টাইপ",
    serviceType: "সার্ভিস টাইপ",
    revenueTier: "রেভিনিউ টায়ার",
    expectedRevenue: "মাসিক প্রত্যাশিত রেভিনিউ",
    expectedCloseDate: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ",
    status: "স্ট্যাটাস",
    checkIn: "চেক ইন সময়",
    checkOut: "চেক আউট সময়",
    remark: "মন্তব্য",
    location: "অবস্থান",
    image: "ছবি",
  },
  createContact: {
    pageHeadline: "যোগাযোগ তথ্য",
    nameLabel: "নাম",
    namePlaceholder: "নাম লিখুন",
    numberLabel: "নম্বর",
    numberPlaceholder: "নম্বর লিখুন",
    roleError: "একটি রোল নির্বাচন করুন",
  },
  createContract: {
    pageHeadline: "কনট্রাক্ট তথ্য",
    orderTypeLabel: "অর্ডার টাইপ",
    serviceTypeLabel: "সেবা টাইপ",
    revenueTierLabel: "রেভিনিউ টিয়ার",
    statusLabel: "স্ট্যাটাস",
    selectPlaceholder: "বাছাই করুন",
    monthlyRevenueLabel: "প্রত্যাশিত মাসিক রেভিনিউ",
    monthlyRevenuePlaceholder: "প্রত্যাশিত মাসিক রেভিনিউ লিখুন",
    expectedCloseDateLabel: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ",
    expectedCloseDatePlaceholder: "তারিখ নির্বাচন করুন",
    primaryOrder: "প্রাইমারি",
    secondaryOrder: "সেকেন্ডারি",
    tertiaryOrder: "টারশিয়ারি",
    successMessage: "কনট্রাক্ট সফলভাবে তৈরি হয়েছে",
    statusError: "স্ট্যাটাস নির্বাচন করুন",
    closeDateError: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ করুন",
    monthlyRevenueError: "মাসিক রেভিনিউ পরিমাণ লিখুন",
    revenueTierError: "রেভিনিউ টিয়ার নির্বাচন করুন",
    serviceTypeError: "সেবা টাইপ নির্বাচন করুন",
    orderTypeError: "অর্ডার টাইপ নির্বাচন করুন",
  },
  orderForecast: {
    supplierLabel: "সাপ্লাইয়ার",
    supplierPlaceholder: "একটি সাপ্লাইয়ার নির্বাচন করুন",
    supplierError: "দয়া করে সাপ্লাইয়ার নির্বাচন করুন",
    deliveryDateLabel: "আর্থিক সহায়তা গ্রহণের সম্ভাব্য তারিখ",
    deliveryDatePlaceholder: "একটি তারিখ নির্বাচন করুন",
    deliveryDateError: "দয়া করে তারিখ নির্বাচন করুন",
    orderAmountLabel: "অর্ডার পরিমাণ",
    orderAmountPlaceholder: "অর্ডার পরিমাণ লিখুন",
    orderAmountError: "দয়া করে অর্ডার পরিমাণ লিখুন",
    financeSupportLabel: "আর্থিক সহায়তা",
    financeSupportPlaceholder: "প্রত্যাশিত আর্থিক সহায়তা লিখুন",
    financeSupportError: "দয়া করে প্রত্যাশিত আর্থিক সহায়তা লিখুন",
    btnLabel: "নিশ্চিত করুন",
    successMessage: "ভবিষ্যতের অর্ডার সফলভাবে তৈরি হয়েছে",
    selectSupplierPageTitle: "সাপ্লাইয়ার তালিকা",
    supplierSearchPlaceholder: "সাপ্লাইয়ার অনুসন্ধান করুন",
  },
};
export default bangla;
