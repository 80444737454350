import { IonToolbar } from "@ionic/react";
import React from "react";
import HomeIcon from "assets/images/icon/svg/Home";
import AdminUser from "assets/images/icon/svg/AdminUser";
import HomeOutline from "assets/images/icon/svg/HomeOutline";
import Order from "assets/images/icon/svg/OrderOutline";
import OrderOutline from "assets/images/icon/svg/Order";
import Analytics from "assets/images/icon/svg/Chart";
import AnalyticsOutline from "assets/images/icon/svg/ChartOutline";
import ReferOutline from "assets/images/icon/svg/PhoneCallOutline";
import Refer from "assets/images/icon/svg/PhoneCall";
import AdminUserOutline from "assets/images/icon/svg/AdminUserOutline";
import { hasActive } from "helpers/helper";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CheckBusiness from "components/CheckBusiness";

export default function NavFooter() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <CheckBusiness />
      <IonToolbar>
        <div
          className="bg-white pb-2 bottom-navigation mt-1 pt-1"
          style={{
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.15)",
          }}
        >
          <ul className="flex items-baseline justify-around">
            <li
              className={`${hasActive(
                "/home",
                pathname
              )} cursor-pointer flex flex-col  items-center`}
              onClick={() => navigate("/home")}
            >
              {hasActive("/home", pathname) === "active" ? (
                <HomeIcon />
              ) : (
                <HomeOutline />
              )}

              <span
                className={`${
                  hasActive("/home", pathname) === "active"
                    ? "font-bold text-success"
                    : "font-semibold text-A6A6A6"
                }  text-10 mt-1`}
              >
                {ekkLanguage.navFooter.home}
              </span>
            </li>

            <li
              className={`${hasActive(
                "/task-list",
                pathname
              )} cursor-pointer flex flex-col justify-center  items-center`}
              onClick={() => navigate("/task-list")}
            >
              {hasActive("/task-list", pathname) === "active" ? (
                <img
                  className="w-6 h-6"
                  src="/assets/images/test_green.png"
                  alt=""
                />
              ) : (
                <img className="w-6 h-6" src="/assets/images/test.png" alt="" />
              )}

              <span
                className={`${
                  hasActive("/task-list", pathname) === "active"
                    ? "font-bold text-success"
                    : "font-semibold text-A6A6A6"
                }  text-10 mt-1`}
              >
                {ekkLanguage.letNav.taskMenuLabel}
              </span>
            </li>
            {/* <li
              className={`${hasActive(
                "/contract-management",
                pathname
              )} cursor-pointer flex flex-col justify-center  items-center`}
              onClick={() => navigate("/contract-management")}
            >
              {hasActive("/contract-management", pathname) === "active" ? (
                <img
                  className="w-6 h-6"
                  src="/assets/images/crm_green.png"
                  alt=""
                />
              ) : (
                <img className="w-6 h-6" src="/assets/images/crm.png" alt="" />
              )}

              <span
                className={`${
                  hasActive("/contract-management", pathname) === "active"
                    ? "font-bold text-success"
                    : "font-semibold text-A6A6A6"
                }  text-10 mt-1`}
              >
                {ekkLanguage.letNav.analyticsMenuLabel}
              </span>
            </li> */}
            <li
              className={`${hasActive(
                "/refer-list",
                pathname
              )} cursor-pointer flex flex-col justify-center  items-center`}
              onClick={() => navigate("/refer-list")}
            >
              {hasActive("/refer-list", pathname) === "active" ? (
                // <Refer />
                <img
                  className="w-6 h-6"
                  src="/assets/images/refer_green.png"
                  alt=""
                />
              ) : (
                // <ReferOutline />
                <img
                className="w-6 h-6"
                src="/assets/images/refer.png"
                alt=""
              />
              )}

              <span
                className={`${
                  hasActive("/refer-list", pathname) === "active"
                    ? "font-bold text-success"
                    : "font-semibold text-A6A6A6"
                }  text-10 mt-1`}
              >
                {ekkLanguage.letNav.referMenuLabel}
              </span>
            </li>

            <li
              className={`${hasActive(
                "/profile",
                pathname
              )} cursor-pointer flex flex-col justify-center  items-center`}
              onClick={() => navigate("/profile")}
            >
              {hasActive("/profile", pathname) === "active" ? (
                <AdminUser />
              ) : (
                <AdminUserOutline />
              )}

              <span
                className={`${
                  hasActive("/profile", pathname) === "active"
                    ? "font-bold text-success"
                    : "font-semibold text-A6A6A6"
                }  text-10 mt-1`}
              >
                {ekkLanguage.navFooter.account}
              </span>
            </li>
          </ul>
        </div>
      </IonToolbar>
    </>
  );
}
