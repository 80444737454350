import { IonButton } from "@ionic/react";
import ContractUser from "assets/images/icon/svg/ContractUser";
import { getLanguage } from "config/language";
import { useNavigate } from "react-router-dom";
import MainBanner from "./MainBanner";
import ContractDashboard from "./ContractDashboard";

function HomePageBanner({ ekkUser, ekkLanguage, dashboard, userTypeData }) {
  const lang = getLanguage();
  const navigate = useNavigate();

  return (
    <div className="pt-20 px-5">
      {ekkUser?.kyc_status === "Verified" &&
      (userTypeData?.user_type === "EkkHero Captain" ||
        userTypeData?.user_type === "EkkHero Hub Staff") ? (
        <div
          className="bg-white rounded-xl"
          style={{
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          <ContractDashboard
            ekkLanguage={ekkLanguage}
            dashboard={dashboard}
          ></ContractDashboard>
        </div>
      ) : (
        <MainBanner ekkLanguage={ekkLanguage} lang={lang}></MainBanner>
      )}
      <div className="mt-5 text-center w-full pb-10">
        {ekkUser?.kyc_status === "Verified" &&
        (userTypeData?.user_type === "EkkHero Captain" ||
          userTypeData?.user_type === "EkkHero Hub Staff") ? (
          <IonButton
            onClick={() => {
              navigate("/contract-management");
            }}
            color="success"
            className="font-bold h-10 w-full"
          >
            <div className="w-full flex items-center justify-center">
              {" "}
              <div className="mr-2">
                <ContractUser />
              </div>
              <span className="mt-1 text-white">
                {ekkLanguage.letNav.analyticsMenuLabel}
              </span>
            </div>
          </IonButton>
        ) : ekkUser?.kyc_status === "Verified" ? (
          <IonButton
            onClick={() => {
              navigate("/registration");
            }}
            color="success"
            className="font-bold h-10 w-full text-white"
          >
            {ekkLanguage.home.updateBtnLabel}{" "}
          </IonButton>
        ) : (
          <IonButton
            onClick={() => {
              navigate("/registration");
            }}
            color="primary"
            className="font-bold h-10 w-full text-white"
          >
            {ekkLanguage.home.registerBtnLabel}{" "}
          </IonButton>
        )}
      </div>
    </div>
  );
}
// export default withLocation(Home);
export default HomePageBanner;
