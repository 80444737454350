import LeftArrow from "assets/images/icon/svg/LeftArrow";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DotMenu from "assets/images/icon/svg/DotMenu";
import {
  setCreateCustomerBS,
  setCustomerDetails,
} from "redux/container/userSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import {
  creditFee,
  paymentMethod,
  paymentTerm,
  setPaymentMethodText,
  setPaymentName,
  deliveryDate,
} from "redux/container/paymentMethodSlice";
import Scanner from "assets/images/icon/svg/Scanner";
import Bell from "assets/images/icon/svg/Bell";

export default function BackButtonTitle({
  title,
  burgerMenu,
  menuIconClick,
  from,
  isHide, // * for Back Button Hide
  userType, // * It's from CMS List to Disable Customer Creation for Hub Staff
}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const onCloseModal = () => {
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };

  return (
    <>
      <div className=" bg-primary z-10 flex px-4 items-center justify-between py-2 fixed left-0 top-0 w-full">
        <div className="flex justify-between items-center">
          {burgerMenu ? (
            <div
              className={`flex items-center justify-center h-10 cursor-pointer`}
              onClick={menuIconClick}
            >
              {/* <Scanner></Scanner> */}
            </div>
          ) : isHide ? (
            <div className="h-10"></div>
          ) : (
            <div
              className="flex items-center justify-center h-10 w-10 cursor-pointer"
              onClick={() => {
                if (location.pathname === "/create-order") {
                  let localCart = localStorage.getItem("cart");
                  let cartParse = JSON.parse(localCart);
                  if (cartParse?.length > 0) {
                    setIsOpen(true);
                  } else {
                    navigate(-1);
                  }
                } else if (from === "customerListPage") {
                  navigate("/home");
                } else if (
                  from === "createCustomer" ||
                  from === "customerDetails"
                ) {
                  navigate("/contract-management");
                } else if (from === "businessInfoPage") {
                  navigate("/create-customer/my-distributor");
                } else if (from === "brandListPage") {
                  navigate("/create-business-info");
                } else {
                  navigate(-1);
                }
              }}
            >
              <LeftArrow color="#ffffff" />
            </div>
          )}
        </div>
        <h5 className="text-white text-18 font-bold">{title}</h5>
        <div>
          <ul className="flex items-center">
            {burgerMenu ? (
              <li className="pr-2">
                <div className="cursor-pointer">{/* <Bell></Bell> */}</div>
              </li>
            ) : (
              ""
            )}
            {from === "customerListPage" && userType !== "EkkHero Hub Staff" && (
              <li className="mx-1">
                <span onClick={() => dispatch(setCreateCustomerBS(true))}>
                  <DotMenu />
                </span>
              </li>
            )}
            {from === "customerDetails" && (
              <li className="mx-1">
                <span onClick={() => dispatch(setCustomerDetails(true))}>
                  <DotMenu />
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
      <Modal
        closeTimeoutMS={200}
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        id="pwa-modal"
        style={customStyles}
      >
        <div className="p-5">
          <span className="mb-2 inline-block mx-2">
            <CancelPopUp />
          </span>
          <h6 className="text-16 font-bold pb-[10px]">
            {ekkLanguage.others.alert}
          </h6>
          <p className="text-12 font-medium pb-[10px] text-black-500">
            {ekkLanguage.others.backButtonMessage}
          </p>
          <div className="flex justify-end pt-3">
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white text-14"
              onClick={() => {
                dispatch(deliveryDate(""));
                dispatch(creditFee(0));
                dispatch(paymentTerm(0));
                dispatch(paymentMethod(""));
                dispatch(setPaymentMethodText(""));
                dispatch(setPaymentName(""));
                localStorage.removeItem("cart");
                navigate("/home");
              }}
            >
              {ekkLanguage.others.okBtnLabel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
