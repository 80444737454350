import { combineReducers } from "redux";
import auth from "redux/container/authSlice";
import businessSettingsSlice from "redux/container/businessSettingsSlice";
import user from "redux/container/userSlice";
import { counterSlice } from "redux/container/counter/counterSlice";
import orderSlice from "redux/container/orderSlice";
import paymentMethodSlice from "redux/container/paymentMethodSlice";
import shoppingCartSlice from "redux/container/shoppingCartSlice";
import languageSlice from "redux/container/languageSlice";
import supplierSlice from "redux/container/supplierSlice";
import learningSlice from "redux/container/learningSlice";
import customerSlice from "redux/container/customerSlice";

const appReducer = combineReducers({
  auth,
  user,
  cart: shoppingCartSlice,
  counter: counterSlice,
  order: orderSlice,
  payment: paymentMethodSlice,
  lan: languageSlice,
  businessSettings: businessSettingsSlice,
  supplier: supplierSlice,
  learning: learningSlice,
  customer: customerSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") state = undefined;
  return appReducer(state, action);
};
export default rootReducer;
