import { createSlice } from "@reduxjs/toolkit";
import { getLanguage } from "config/language";
import bangla from "i18n/bn";
import english from "i18n/en";
import vietnamese from "i18n/vn";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    lan:
      getLanguage() === "bn"
        ? bangla
        : getLanguage() === "vn"
        ? vietnamese
        : english,
  },
  reducers: {
    selectLan: (state) => {
      if (getLanguage() === "bn") {
        state.lan = bangla;
      } else if (getLanguage() === "vn") {
        state.lan = vietnamese;
      } else {
        state.lan = english;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectLan } = languageSlice.actions;
export const { actions, reducer } = languageSlice;
export default reducer;
