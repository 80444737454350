import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Blog = {
  getLatestJobList: (page = 1, lan = "EN", limit = 5, country) => {
    return SuperFetch(
      `${baseUrl.social}/articles/filter/app-name/?page=${page}&limit=${limit}&language=${lan}&apps=Ekkhero&categories=EkkHero Job Post`
    );
  },
  singleJobPost: (id) => {
    return SuperFetch(`${baseUrl.social}/articles/${id}`);
  },
  learningList: (page = 1, limit = 5, lan) => {
    return SuperFetch(
      `${baseUrl.social}/articles/filter/app-name/?page=${page}&limit=${limit}&language=${lan}&apps=Ekkhero&categories=Learning`
    );
  },
  learningDetails: (id) => {
    return SuperFetch(`${baseUrl.social}/articles/${id}`);
  },
  getCommentList: (id, page = 1, limit = 10) => {
    return SuperFetch(`${baseUrl.social}/articles/comment-list/${id}`);
  },
  addComment: (id, comment) => {
    return SuperFetch(`${baseUrl.social}/articles/add-comment/${id}/`, {
      method: "POST",
      data: comment,
    });
  },
  addLike: (id) => {
    return SuperFetch(`${baseUrl.social}/articles/add-like/${id}/`, {
      method: "PUT",
    });
  },
  getTermConditonWithoutAuth: (lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/?categories=Terms and Conditions&language=${lan}&page=1&limit=10&app=EkkHero`
		);
	},
	getPrivacyPolicyWithoutAuth: (lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/?categories=Privacy Policy&language=${lan}&page=1&limit=10&app=EkkHero`
		);
	},
	getRefundPolicyWithoutAuth: (lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/?categories=Refund Policy&language=${lan}&page=1&limit=10&app=EkkHero`
		);
	},
};

export default Blog;
