import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { digitChanger } from "helpers/helper";
import React from "react";

export default function ContractDashboard({ ekkLanguage, dashboard }) {
  return (
    <div className="purchase-summary relative">
      <ion-grid>
        <IonRow>
          <IonCol size="6">
            <div className="text-center rounded-[10px] bg-white p-[10px] ">
              <p className="text-16 text-success font-bold mb-[10px]">
                {ekkLanguage.home.todayPurchaseLabel}
              </p>

              <div className="text-12 text-success font-extrabold mb-[10px] ">
                {dashboard ? (
                  <h6 className="font-bold text-[#262626] text-24 py-2">
                    {digitChanger(dashboard?.business_info)}
                  </h6>
                ) : (
                  <IonSpinner className="my-1" color="green"></IonSpinner>
                )}
              </div>
            </div>
          </IonCol>
          <IonCol size="6">
            <div className="text-center rounded-[10px] bg-white p-[10px]">
              <p className="text-16 text-primary font-bold mb-[10px]">
                {ekkLanguage.home.tillNow}
              </p>

              <div className="text-12 text-primary font-extrabold mb-[10px]">
                {dashboard ? (
                  <h6 className="font-bold text-[#262626] text-24 py-2">
                    {digitChanger(dashboard?.visit)}
                  </h6>
                ) : (
                  <IonSpinner className="my-1" color="red"></IonSpinner>
                )}
              </div>
            </div>
          </IonCol>
        </IonRow>
      </ion-grid>
    </div>
  );
}
