import Comment from "assets/images/icon/svg/Comment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Picker } from "emoji-mart";
// import "emoji-mart/css/emoji-mart.css";
import Blog from "services/Blog";
import { getLocalUser, getUser } from "config/user";
import { toast } from "react-toastify";
import { digitChanger, monthChanger } from "helpers/helper";

export default function LearningDetails({
  comments,
  learningDetails,
  id,
  setLearningDetails,
  setComments,
  count,
  setCount,
  ekkLanguage,
}) {
  const [comment, setComment] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [error, setError] = useState("");
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const localUser = getUser();
  const [showAnim, setShowAnim] = useState(false);
  const user = getLocalUser();

  useEffect(() => {
    const animationCSS = `
		  @keyframes myAnimation {
			0% {
			  transform: translate(0, 0) scale(1);
			}
			50% {
			  transform: translate(2px, 2px) scale(1.1);
			}
			100% {
			  transform: translate(0, 0) scale(1);
			}
		  }`;

    const styleElement = document.createElement("style");
    styleElement.textContent = animationCSS;

    document.head.appendChild(styleElement);
  }, []);

  const handleEmojiClick = (emoji) => {
    setComment(comment + emoji.native);
  };

  const handleComment = async () => {
    if (comment !== "") {
      let response = await Blog.addComment(id, {
        content: comment,
      });

      if (response.success) {
        setComment("");
        setError("");
        let response = await Blog.learningDetails(id);
        if (response.status === 200) {
          setCount(response?.results?.comments?.length);
          setComments(response?.results?.comments);
        }
      }
    } else {
      setError("Please enter a comment!");
    }
  };

  const handleLike = async () => {
    let response = await Blog.addLike(id);
    if (response.status === 200) {
      setShowAnim(true);
      let res = await Blog.learningDetails(id);
      if (res.status === 200) {
        setAnimationPlayed(true);
        setLearningDetails(res.data);
      }
    }
  };

  const userLikeCheck = () => {
    if (learningDetails?.bazzed_users) {
      for (const bazz of learningDetails?.bazzed_users) {
        if (localUser !== null) {
          if (bazz === localUser?._id) {
            return true;
          }
        } else {
          if (bazz === user?.user?._id) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  };

  return (
    <div className="relative">
      <div className="py-2 px-4 bg-white ">
        {/* Banner  */}
        <div className="rounded-[8px] mb-[10px]">
          <img
            src={learningDetails?.thumbnail_image}
            className="w-full"
            alt="slideImage"
          />
        </div>
        {/* Title  */}
        <h6
          // onClick={() => navigate(`/learning-details/${learn._id}`)}
          className="font-bold text-16 mb-[5px]"
        >
          {learningDetails.title}
          {learningDetails?.tags?.length > 4 && <br />}
          {learningDetails.tags &&
            learningDetails.tags.length > 0 &&
            learningDetails.tags.map((tag, index) => {
              return (
                <span key={index} className="text-delivered px-0.5">
                  #{tag}
                </span>
              );
            })}
        </h6>
        {/* Created Date  */}
        <span className="text-808080 font-semibold text-12">
          {digitChanger(
            monthChanger(moment(learningDetails.createdAt).format("DD MMM, YY"))
          )}
        </span>
        {/* Content  */}
        <div
          className="text-14 font-medium text-222222 text-justify"
          dangerouslySetInnerHTML={{
            __html: learningDetails.content,
          }}
        />

        {/* Like & Comments Icon Section  */}
        <div className="flex items-center justify-between py-[2px] ">
          <div className="flex items-center">
            {!userLikeCheck() && !animationPlayed ? (
              <button className="flex items-center" onClick={handleLike}>
                <img
                  src="/assets/images/like.png"
                  alt="icon"
                  className="mr-[7px] w-[26px] h-[26px]"
                />
                <h6 className="text-18 font-semibold pt-2">
                  {digitChanger(learningDetails?.bazz_count)}
                </h6>
              </button>
            ) : (
              <button
                className="flex items-center"
                onClick={() => toast.error(ekkLanguage.learning.likedWarning)}
              >
                <img
                  style={{
                    animation: `${showAnim && "myAnimation 1s"}`,
                    animationPlayState: `${showAnim && "running"}`,
                  }}
                  src="/assets/images/liked.png"
                  alt="icon"
                  className="mr-[7px] w-[26px] h-[26px]"
                />
                <span className="text-18 font-semibold pt-2">
                  {digitChanger(learningDetails?.bazz_count)}
                </span>
              </button>
            )}
          </div>

          <div className="flex items-center">
            <img
              src="/assets/images/message.png"
              alt="icon"
              className="mr-[7px] w-[26px] h-[26px]"
            />
            <span className="text-16 font-semibold">{digitChanger(count)}</span>
          </div>
        </div>

        {/* Comments Display Section  */}
        <div className="mt-[10px] pb-[60px]">
          <h6 className="text-[#000] text-18 font-semibold">
            {ekkLanguage.learning.comments}
          </h6>
          {comments?.length > 0
            ? comments.map((comment) => (
                <div key={comment?._id} className="mt-[15px] flex">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={comment?.author?.profile_image}
                    alt=""
                  />
                  <div className="flex flex-col ml-[10px]">
                    <div className="flex w-full">
                      <p className="text-14 font-bold text-[#000] ">
                        {comment?.author?.DisplayName}{" "}
                        <span className="text-14 font-bold text-[#00000080]">
                          <span className="px-0.5">| </span>
                          {digitChanger(
                            monthChanger(
                              moment(comment.createdAt).format("DD MMM, YY")
                            )
                          )}
                          <span className="px-0.5">| </span>
                          {digitChanger(
                            monthChanger(
                              moment(comment.createdAt).format("h:mm a")
                            )
                          )}
                        </span>
                      </p>
                    </div>
                    <p className="text-12 font-semibold text-[#222] text-justify">
                      {comment.content}
                    </p>
                  </div>
                </div>
              ))
            : ""}
        </div>
        {/* Error if write empty comment */}
        <div className="px-1">
          {error && <p className="text-red-500 text-12 font-bold">{error}</p>}
        </div>
      </div>

      {/* Write Comment  */}
      <div className="flex items-center w-full px-4 bg-[#F7F7F7] py-3 fixed left-0 bottom-0">
        <div className="flex items-center relative w-full">
          <input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleComment();
              }
            }}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            type="text"
            placeholder={`${ekkLanguage.learning.commentAs} `}
            className="py-3 px-3 appearance-none block w-full border rounded-[47px] mr-4 leading-tight focus:outline-1 bg-[#E6E6E6] focus:bg-white text-14"
          />

          {/* <button
						onClick={() => setShowEmojiPicker(!showEmojiPicker)}
						className='absolute w-5 h-5 right-6 top-2.5'
					>
						😀
					</button> */}

          {/* {showEmojiPicker && (
						<div className='emoji-picker absolute top-10'>
							<Picker onSelect={handleEmojiClick} previewPosition='none' />
						</div>
					)} */}
        </div>
        <button onClick={handleComment}>
          <Comment />
        </button>
      </div>
    </div>
  );
}
