import React from "react";

import Men from "assets/images/icon/svg/Men";
import Lock from "assets/images/icon/svg/Lock";
import SignOut from "assets/images/icon/svg/SignOut";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/container/authSlice";
import RightArrow from "assets/images/icon/svg/RightArrow";
import { Link } from "react-router-dom";
import Globe from "assets/images/icon/svg/Globe";

export default function SettingsCard() {
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='px-5 profile-settings-card'>
			<div className='text-center bg-white rounded-[30px]'>
				<div className='pt-6 pb-3 px-5'>
					<ul>
						<li className='mb-4'>
							<Link
								to='/contact'
								className='mb-4 flex items-center justify-between'
							>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}
									>
										<Men />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.contactMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to='/otp-send'
								className='mb-4 flex items-center justify-between'
							>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}
									>
										<Lock />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.passwordMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to='/change-language'
								className='mb-4 flex items-center justify-between'
							>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}
									>
										<Globe />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.changeLanguageMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to='/referral-code'
								className='mb-4 flex items-center justify-between'
							>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}
									>
										<img
											src='/assets/images/ekkhero/Icons/refer.png'
											alt='refer'
										/>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.refer}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to='/#!'
								onClick={() => {
									dispatch(logout());
								}}
								className='mb-4 flex items-center justify-between'
							>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}
									>
										<SignOut />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.logoutMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
