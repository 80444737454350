import React, { useEffect, useState } from "react";
import LandingNavBar from "./LandingNavBar";
import TextBannerArea from "./TextBannerArea";
import PhoneNumber from "./PhoneNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  actions,
  selectors,
  setAppUrl,
  setPreUrl,
} from "redux/container/authSlice";
import { setLanguage } from "config/language";
import FooterV2 from "./FooterV2";
import { baseUrl } from "config/apiUrl";
import SentOtpV2 from "./SentOtpV2";

export default function PhoneNumberCheck() {
  const { app, link } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+88");
  const [country, setCountry] = useState("Bangladesh");
  const [phoneNo, setPhoneNo] = useState("");
  var sSize = window.matchMedia("(max-width: 599px)");
  const { loginStatus } = useSelector(selectors.getAuth);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  var parts = location?.search?.split("?");
  useEffect(() => {
    if (app === "ekkhero_pilot_log_in") {
      navigate(loginStatus ? "/home" : `/${app}`);
      dispatch(setAppUrl({ app: app, link: baseUrl.ehp }));
    } else if (parts[1]) {
      dispatch(actions.logout());
      localStorage.removeItem("master_token");
      localStorage.removeItem("_u");
      localStorage.removeItem("_b");
      localStorage.removeItem("cb");
      localStorage.setItem("auth", "false");
      dispatch(setPreUrl(parts[1]));
      navigate(loginStatus ? "/home" : `/${parts[1] ? `?${parts[1]}` : "/"}`);
    } else {
      console.log("Login true");
      dispatch(setPreUrl(""));
      navigate(loginStatus ? "/home" : "/");
    }
  }, [loginStatus]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }
  console.log(parts)
  return (
    <>
      <LandingNavBar />
      <div className="container w-full h-screen flex items-center justify-center lg:flex-row flex-col overflow-y-scroll">
        <TextBannerArea ekkLanguage={ekkLanguage} />
        {parts?.length > 1 ? (
          <SentOtpV2
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            country={country}
            setCountry={setCountry}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            sSize={sSize}
          />
        ) : (
          <PhoneNumber
            sSize={sSize}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            country={country}
            setCountry={setCountry}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
        )}
      </div>
      <FooterV2 />
    </>
  );
}
