const vietnamese = {
  login: {
    contryLabel: "QUỐC GIA",
    contryPlaceHolder: "",
    phoneLable: "SỐ ĐIỆN THOẠI",
    phonePlaceHolder: "SỐ ĐIỆN THOẠI",
    validationLabel: "Vui lòng nhập số điện thoại hợp lệ bằng tiếng Anh",
    btnLabel: "Tiếp tục",
    tokenSuccessLabel: "Mã OTP của bạn đã được gửi đến điện thoại của bạn",
    tokenSuccessFailed: "OTP của bạn chưa được tạo, vui lòng thử lại",
    langChange: "Thay đổi ngôn ngữ thành",
    termsAllow: "Vui lòng cho phép điều khoản và điều kiện khi đăng nhập!",
  },
  password: {
    passwordLabel: "MẬT KHẨU",
    passwordPlaceHolder: "Nhập mật khẩu của bạn",
    forgetPass: "Quên mật khẩu",
    backWord: "Quay lại",
    btnLabel: "Đăng nhập",
    otpSendLabel: "OTP đã được gửi đến điện thoại của bạn",
    passwordNotMatch: "Mật khẩu không khớp",
  },
  changePass: {
    passwordLabel: "MẬT KHẨU MỚI",
    passwordPlaceHolder: "*******************",
    confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
    confirmPassPlaceHolder: "*******************",
    passNotMatchLabel: "Mật khẩu không khớp",
    btnLabel: "Xác nhận Mật khẩu",
    successLabel: "Mật khẩu của bạn đã thay đổi thành công",
    errorLabel: "Thay đổi mật khẩu của bạn thất bại",
    lengthErrorLabel: "Mật khẩu phải có ít nhất 4 ký tự",
    notMatchLabel: "Mật khẩu và xác nhận mật khẩu không khớp.",
  },
  token: {
    tokenLabel: "OTP",
    tokenPlaceHolder: "Nhập mã OTP của bạn",
    notGet: "Không nhận được mã?",
    tokenResend: "Yêu cầu lại",
    tokenSuccessLabel:
      "Mã đã được gửi đến điện thoại của bạn, nhập để tiếp tục",
    tokenErrorLabel: "Gửi OTP thất bại",
    timerLabel: "Vui lòng đợi",
    second: "giây",
    btnLabel: "Xác nhận OTP",
    tokenNotMatch: "Mã OTP của bạn không đúng",
    tokenVarified: "Mã OTP của bạn đã được xác minh",
    tokenNotWrite: "Vui lòng nhập mã OTP hợp lệ của bạn",
  },
  registration: {
    nameLabel: "HỌ TÊN ĐẦY ĐỦ",
    namePlaceholder: "Nhập họ tên đầy đủ của bạn",
    createProfile: "Tạo Hồ Sơ Của Bạn",
    helpUs: "Vui lòng giúp chúng tôi bằng cách cung cấp thông tin chính xác",
    emailLabel: "EMAIL",
    emailPlaceholder: "Nhập email của bạn",
    passwordLabel: "MẬT KHẨU",
    passwordPlaceholder: "Nhập mật khẩu của bạn",
    confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
    confirmPassPlaceholder: "Nhập xác nhận mật khẩu của bạn",
    validationLabel: "Trường nhập phải có ít nhất 4 ký tự",
    passNotMatch: "Mật khẩu và xác nhận mật khẩu không khớp.",
    btnLabel: "Tiếp tục",
    successMessage: "Hồ sơ được tạo thành công",
    errorMessage: "Vui lòng kiểm tra thông tin hợp lệ",
    passLengthValidLabel: "Mật khẩu phải có ít nhất 4 ký tự",
    dobLabel: "Ngày Sinh",
    day: "Ngày",
    month: "Tháng",
    year: "Năm",
    genderLabel: "Giới tính",
    male: "Nam",
    female: "Nữ",
    others: "Khác",
    passwordLength: "Mật khẩu của bạn là",
    strong: "Mạnh mẽ'",
    medium: "Trung bình",
    weak: "yếu",
  },
  letNav: {
    homeMenuLabel: "Trang Chủ",
    taskMenuLabel: "Danh Sách Công Việc",
    analyticsMenuLabel: "Quản lý Hợp đồng",
    referMenuLabel: "Danh Sách Giới Thiệu",
    orderMenuLabel: "Lịch Sử Đặt Hàng",
    mySupplierMenuLabel: "Nhà Cung Cấp Của Tôi",
    businessProfileMenuLabel: "Cài Đặt Hồ Sơ Doanh Nghiệp",
    phoneCall: "Giới thiệu",
  },

  jobSelect: {
    titlePart1: "Chào mừng bạn đến với Cộng đồng EkkHero của chúng tôi.",
    ekkHero: "EkkHero",
    titlePart2: "bạn có thể hoàn thành nhiệm vụ của mình và ",
    earnMoney: "Kiếm tiền.",
    sales: "Bán hàng",
    delivery: "Giao hàng",
    others: "Khác",
    btnLabel: "Tiếp theo",
    navLabel: "Chọn một hoặc nhiều",
  },
  home: {
    showMoreLabel: "Xem thêm",
    registerBtnLabel: "Đăng ký",
    updateBtnLabel: "Cập nhật Thông tin",
    brandLabel: "Thương hiệu",
    offerLabel: "Ưu đãi mới",
    mySuppliersLabel: "Nhà cung cấp của tôi",
    nearSuppliersLabel: "Nhà cung cấp gần bạn",
    recommendentLabel: "Dành riêng cho bạn",
    searchLabel: "Tìm kiếm sản phẩm",
    todayPurchaseLabel: "Tổng Khách hàng",
    totalOrderLabel: "Sản phẩm từ",
    totalOrder: "Đơn hàng",
    skusLabel: "SKUs",
    tillNow: "Thăm hôm nay",
    orderDue: "Đơn hàng đến hạn",
    brandProductPageTitle: "Tất cả Thương hiệu",
    offerProductPageTitle: "Sản phẩm ưu đãi",
    offerProductNotFound: "Xin lỗi, không có ưu đãi nào phù hợp với bạn.",
    mySupplierNotFound:
      "Bạn chưa có bất kỳ nhà cung cấp nào. Vui lòng mua sản phẩm để thêm nhà cung cấp của bạn vào đây.",
    supplierNotFound:
      "Xin lỗi, không có nhà cung cấp nào được tìm thấy trong khu vực của bạn. Chúng tôi sẽ sớm phân công một số nhà cung cấp trong khu vực của bạn.",
    brandSeller: "Nhà cung cấp",
    allBrandSeller: "Tất cả nhà cung cấp",
    km: "km",
    step1Title: "Bước 1 - Đăng ký thành EkkHero",
    step1Content:
      "Gửi thông tin của bạn để được chấp nhận là EkkHero. Bạn không thể nộp đơn vào bất kỳ công việc nào cho đến khi bạn đăng ký.",
    step2Title: "Bước 2 - Chờ xác minh ID của bạn",
    step2Content:
      "Khi hoàn tất việc đăng ký, hãy chờ xác minh ID của bạn. Nút đăng ký và hồ sơ sẽ chuyển sang màu xanh khi xác minh ID của bạn hoàn thành.",
    step3Title: "Bước 3 - Nộp đơn cho Nhiệm vụ/Công việc",
    step3Content: "Xem danh sách nhiệm vụ/công việc và nộp đơn cho chúng.",
    recommendentionLabel: "Danh sách Học tập",
    applyNowBtnLabel: "Nộp đơn ngay",
    clickInfoLabel:
      "Nhấp vào nút “Nộp đơn ngay” màu xanh để làm công việc này.",
    jobDetailsTitle: "Chi tiết Công việc/Nhiệm vụ",
  },

  addressVerify: {
    nidPlaceholder1: "NID - Mặt trước",
    nidPlaceholder2: "NID - Mặt sau",
    nidSuccessMessage: "NID đã được tải lên thành công!",
    nidPlaceholder3: "Tải ảnh của bạn ở đây hoặc duyệt",
    nidPlaceholder4:
      "Hỗ trợ kích thước tệp tối đa 2MB, JPG, JPEG, PNG hoặc PDF",
    mainTitle: "Xác minh tài khoản",
    addressTitlePart1: "Vui lòng cung cấp thông tin",
    addressTitlePart2: "Chính xác của bạn",
    presentAddressLabel: "Địa chỉ hiện tại",
    presentAddressPlaceholder: "Nhập địa chỉ đầy đủ của bạn",
    presentCityLabel: "Thành phố hiện tại",
    presentCityPlaceholder: "Thành phố",
    postalCodeLabel: "Mã bưu điện",
    postalCodePlaceholder: "Mã bưu điện",
    nameLabel: "Tên",
    namePlaceholder: "Nhập tên cha/mẹ/anh/em của bạn",
    numberLabel: "Số điện thoại",
    numberPlaceholder: "Nhập số điện thoại của cha/mẹ/anh/em của bạn",
    extraLabel: "Liên hệ khẩn cấp",
    cancel: "Tôi sẽ cung cấp thông tin này sau",
    successMessage: "Địa chỉ đã được lưu thành công!",
    gaurdianNumberError: "Vui lòng nhập số điện thoại liên hệ khẩn cấp!",
    postalCodeError: "Vui lòng nhập mã bưu điện!",
    cityError: "Vui lòng nhập tên thành phố!",
    presentAddressError: "Vui lòng nhập địa chỉ hiện tại!",
  },
  workVerify: {
    workVerifyTitle: "Loại công việc & Lương",
    workTypeLabel: "Loại công việc",
    workTypeOption1: "Bán thời gian",
    workTypeOption2: "Toàn thời gian",
    previousWorkExperienceLabel: "Kinh nghiệm làm việc trước đây",
    previousWorkExperienceOption1: "Có",
    previousWorkExperienceOption2: "Không",
    monthlySalaryLabel: "Lương hàng tháng",
    monthlySalaryLabel2: "Dự kiến từ EkkBaz",
    monthlySalaryPlaceholder: "Nhập mức lương hàng tháng dự kiến của bạn",
    workAreaLabel: "Quận/Huyện",
    workAreaLabel3: "Phường/Xã",
    workAreaLabel2: "Khu vực làm việc quan tâm",
    workAreaPlaceholder: "Chọn khu vực làm việc quan tâm của bạn",
    workAreaPlaceholderWarning: "Vui lòng chọn một Quận/Huyện trước",
    academicQualificationLabel: "Trình độ học vấn",
    academicQualificationLabel2: "Vui lòng chọn trình độ học vấn của bạn",
    academiOption1: "Lớp 1 đến 5 (PSC)",
    academiOption2: "Lớp 1 đến 8 (JSC)",
    academiOption3: "Lớp 1 đến 10 (SSC)",
    academiOption4: "Lớp 1 đến 12 (HSC)",
    academiOption5: "Khác",
    addFileLabel: "Thêm CV của bạn",
    addFileLabel2: "Vui lòng tải lên một tệp PDF",
    addFilePlaceholder: "Duyệt tệp",
    successMessage: "Thông tin về công việc và lương đã được lưu thành công!",
    workError: "Vui lòng chọn loại công việc!",
    experienceError: "Vui lòng chọn kinh nghiệm làm việc trước đây!",
    districtError: "Vui lòng chọn quận/huyện bạn quan tâm để làm việc!",
    upazillaError: "Vui lòng chọn phường/xã bạn quan tâm để làm việc!",
  },
  skillVerify: {
    skillVerifyTitle: "Ngành công nghiệp & Kỹ năng",
    industryLabel: "Ngành công nghiệp làm việc",
    industryLabel2: "Vui lòng chọn ngành công nghiệp làm việc của bạn",
    food: "Thực phẩm",
    fashion: "Thời trang",
    pharmacy: "Dược phẩm",
    marketing: "Marketing",
    electronics: "Điện tử",
    digitalServices: "Dịch vụ số",
    it: "Công nghệ thông tin",
    design: "Thiết kế",
    banking: "Ngân hàng",
    insurance: "Bảo hiểm",
    cosmetics: "Mỹ phẩm",
    logistics: "Vận chuyển và logistics",
    skillLabel: "Kỹ năng",
    skillLabel2: "Vui lòng chọn kỹ năng tốt nhất của bạn",
    driving: "Lái xe an toàn",
    navigation: "Định vị",
    customerService: "Dịch vụ khách hàng",
    vehicle: "Kiến thức bảo dưỡng phương tiện",
    fitness: "Sức khỏe và sức mạnh thể chất",
    problemSolving: "Giải quyết vấn đề",
    traffic: "Kiến thức về luật giao thông",
    work: "Làm việc độc lập hiệu quả",
    successMessage: "Ngành công nghiệp & Kỹ năng đã được lưu thành công!",
  },

  drivingVerify: {
    drivingTitle: "Lái xe & Phương tiện",
    haveDrivingLabel: "Bạn có bằng lái xe không?",
    licenceNumberLabel: "Số bằng lái xe",
    licenceNumberPlaceholder: "Nhập số bằng lái xe của bạn",
    haveVehicleLabel: "Bạn có phương tiện không?",
    vehicleTypeLabel: "Chọn loại phương tiện của bạn",
    loadAmountLabel: "Khối lượng chở hàng của phương tiện",
    loadAmountLabel2: "trong KG",
    loadAmountPlaceholder: "Nhập khối lượng chở hàng của phương tiện của bạn",
    vehicleNumberLabel: "Số phương tiện",
    vehicleNumberPlaceholder: "Nhập số phương tiện của bạn",
    truck: "Xe tải",
    car: "Ô tô",
    motorcycle: "Xe máy",
    escooter: "Xe điện",
    bicycle: "Xe đạp",
    drivingError: "Chọn liệu bạn có bằng lái xe hay không!",
    vehicleError: "Chọn liệu bạn có phương tiện hay không!",
    successMessage: "Thông tin lái xe đã được lưu thành công!",
  },
  profile: {
    pageTitle: "Hồ sơ và Cài đặt",
    contactMenuLable: "Liên hệ & Thông tin cơ bản",
    passwordMenuLable: "Đổi mật khẩu",
    logoutMenuLable: "Đăng xuất",
    changeLanguageMenuLable: "Đổi ngôn ngữ",
    refer: "Giới thiệu",
    referNavTitle: "Mã Giới Thiệu",
    referTitle: "Dưới đây là Mã Giới Thiệu của bạn",
    referSubtitle: "Vui lòng chia sẻ nó với khách hàng của bạn",
    referList: "Danh sách giới thiệu",
    referModalTitle:
      "Bạn có chắc chắn muốn loại bỏ khách hàng này khỏi danh sách giới thiệu của bạn không?",
    yes: "Có",
    no: "Không",
  },
  refer: {
    pageTitle: "Mã Giới Thiệu",
    confirmBtnLabel: "Xác nhận",
    skipBtnLabel: "Tôi không có mã giới thiệu nào",
    title: "Áp dụng mã giới thiệu và kiếm điểm",
    subTitle: "Thu thập mã giới thiệu từ bạn bè xung quanh bạn",
    codePlaceholder: "Nhập mã giới thiệu ở đây",
    referCodeErrorMessage: "Vui lòng nhập mã giới thiệu!",
    confirmButtonTitle: "Xác nhận",
    skipButtonTitle: "Tôi không có mã giới thiệu nào",
  },
  changeLanguages: {
    pageTitle: "Chọn Ngôn Ngữ",
    btnLabel: "Lưu",
  },

  contact: {
    pageTitle: "Liên hệ & Thông tin cơ bản",
    nameLabel: "Tên",
    namePlaceholder: "Nhập tên của bạn",
    emailLabel: "Email",
    emailPlaceholder: "Nhập email của bạn",
    nidLabel: "Số CMND",
    nidPlaceholder: "Nhập số CMND",
    btnLabel: "Lưu",
    successLabel: "Hồ sơ của bạn đã được cập nhật thành công",
    currectEmail: "Vui lòng nhập địa chỉ email hiện tại",
  },
  otpsend: {
    pageTitle: "Xác minh Số",
    btnLabel: "Gửi",
    message: "Nhấn để gửi mã xác minh đến điện thoại của bạn qua SMS.",
    successLabel: "Mã OTP của bạn đã được gửi đến điện thoại của bạn",
    errorLabel: "Mã OTP của bạn chưa được tạo, vui lòng thử lại",
  },
  otpsubmit: {
    pageTitle: "Xác minh Số",
    btnLabel: "Gửi",
    title: "Mã Xác Minh",
    paragraph: "Chúng tôi đã gửi mã xác minh đến Số Điện Thoại của bạn",
    otpLabel: "OTP",
    otpPlaceholder: "Nhập mã OTP của bạn",
    receiveYetLabel: "Không nhận được mã?",
    againRequestLabel: "Yêu cầu lại",
    timerLabel: "Vui lòng đợi",
    second: "giây",
    successLabel: "Mã OTP của bạn đã được gửi đến điện thoại của bạn",
    errorLabel: "Mã OTP của bạn chưa được tạo, vui lòng thử lại",
    tokenVarifiedMessage: "Mã OTP của bạn đã được xác minh",
    validationLabel: "Vui lòng gửi mã OTP của bạn",
  },
  changePassword: {
    pageTitle: "Thay đổi mật khẩu",
    btnLabel: "Gửi",
    passwordLabel: "MẬT KHẨU",
    passwordPlaceholder: "Nhập mật khẩu của bạn",
    confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
    confirmPassPlaceholder: "Nhập lại mật khẩu của bạn",
    successLabel: "Mật khẩu của bạn đã được cập nhật",
    errorLabel: "Cập nhật mật khẩu không thành công",
    passNotMatchLabel: "Mật khẩu và xác nhận mật khẩu không khớp",
    changePassSuccessPara: "Mật khẩu của bạn đã được thay đổi thành công",
    changePassContinueBtn: "Tiếp tục",
  },

  locationPicker: {
    pageTitle: "Tìm Kiếm Vị Trí Cửa Hàng",
    btnLabel: "XÁC NHẬN",
    paragraph: `Di chuyển bản đồ đến vị trí của bạn để chọn nó`,
  },
  location: {
    title: "Bật vị trí của bạn",
    paragraph:
      "Ứng dụng này yêu cầu dịch vụ vị trí được bật trên thiết bị của bạn và cho ứng dụng này. Bạn phải bật chúng trong Cài đặt trước khi sử dụng ứng dụng này.",
    btnLabel: "Cho phép chỉ khi đang sử dụng ứng dụng",
  },

  pwaDownload: {
    header: "Tải ứng dụng EkkBaz",
    paragraph:
      "Tải ứng dụng và đặt hàng số lượng lớn từ nhà cung cấp của bạn bất kỳ lúc nào từ bây giờ.",
    cancelBtn: "Hủy",
    downloadBtn: "Tải xuống",
  },

  landing: {
    header1: "Đăng ký EkkHero",
    header2: "Bắt đầu làm việc",
    header3: "Kiếm tiền!",
    header4: "Quên mật khẩu",
    requestOtp: "Yêu cầu OTP",
    login: "Đăng nhập",
    bangla: "বাংলা",
    english: "English",
    vietnamese: "Tiếng Việt",
    retail: "Bán lẻ",
    manufacture: "Nhà cung cấp",
    hotline: "Đường dây nóng",
    phoneNumber: "Số điện thoại",
    phoneNumberPlaceHolder: "Nhập số điện thoại",
    fAbout:
      "Thị trường bán sỉ ngay tại đầu ngón tay của bạn. Mua ngay, trả sau!",
    location: "Địa chỉ",
    singapore: "Văn phòng Singapore",
    bangladesh: "Văn phòng Bangladesh",
    bOfficeAddress: "Tầng 13, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
    otherInfo: "Thông tin khác",
    partners: "Đối tác của chúng tôi",
    news: "Tin tức",
    support: "Hỗ trợ",
    corporate: "Doanh nghiệp",
    refundPolicy: "Chính sách hoàn trả",
    termsCondition: "Điều khoản sử dụng",
    privacyPolicy: "Chính sách bảo mật",
    contactUs: "Liên hệ với chúng tôi",
    messageUs: "Gửi tin nhắn",
    facebook: "Facebook",
    sales: "Bán hàng",
    media: "Phương tiện truyền thông",
    downloadBtn: "Tải ứng dụng",
    backers: "Những người hỗ trợ của chúng tôi",
    benefits: "Lợi ích của bạn",
    discover: "Tin tức mới nhất từ EkkBaz",
    benefitsOne: "Giao hàng đúng hẹn 24 giờ",
    benefitsDisOne: "",
    benefitsTwo: "Giá thị trường tốt nhất",
    benefitsDisOneTwo: "",
    benefitsThree: "Sự đa dạng lớn nhất về SKU",
    benefitsDisOneThree: "",
    benefitsFore: "Hỗ trợ tài chính cho mua sắm",
    benefitsDisOneFore: "",
    seeMore: "Xem thêm",
    weAvailable: "Chúng tôi có sẵn",
  },
  others: {
    alert: "Thông báo",
    backButtonMessage:
      "Giỏ hàng của bạn có một số mặt hàng, nếu bạn rời khỏi trang này, giỏ hàng của bạn sẽ trống.",
    okBtnLabel: "OK",
    loader: "Vui lòng đợi...",
    readMore: "đọc thêm...",
    seeMore: "Xem thêm",
  },
  autoLogOut: {
    pageTitle: "Đăng xuất tự động",
    warningMsg:
      "Tự động đăng xuất vì số này đã được sử dụng trong lần đăng nhập khác",
    instrMsg: "Bấm vào nút xanh bên dưới để đăng nhập lại",
    btnLabel: "Đăng nhập",
  },
  navFooter: {
    home: "Trang chủ",
    order: "Đặt hàng",
    supplier: "Nhà cung cấp",
    learning: "Học tập",
    account: "Tài khoản",
  },
  learning: {
    loader: "Vui lòng đợi...",
    notFound: "Không tìm thấy",
    pageTitle: "Danh sách Học tập",
    learningDetailsTitle: "Chi tiết Học tập",
    comments: "Bình luận",
    commentAs: "Viết bình luận của bạn ở đây",
    likedWarning: "Bạn không thể thích nhiều hơn một lần",
  },
  customerList: {
    pageTitle: "Danh Sách Khách Hàng",
    searchPlaceHolder: "Nhập Tên Khách Hàng của bạn",
    detailsPageTitle: "Chi Tiết Khách Hàng",
    customerDetailsLabel: "Thông Tin Khách Hàng",
    businessTypeLabel: "Loại Hình Doanh Nghiệp",
    customerNameLabel: "Tên",
    addressLabel: "Địa Chỉ",
    postalCodeLabel: "Mã Bưu Điện",
    cityLabel: "Thành Phố",
    acceptedCustomerLabel: "Khách Hàng Đã Chấp Nhận",
    requestedCustomerLabel: "Yêu Cầu Giá",
    accept: "Chấp Nhận",
    acceptConfirmation: "Bạn có chắc chắn muốn chấp nhận Khách Hàng này không?",
    km: "km",
    createCustomer: "Tạo Doanh Nghiệp Mới",
    customerNoHolder: "Số Điện Thoại Người Dùng",
    nextBtn: "Tiếp Theo",
    deleteDistributor: "Xóa Khách Hàng",
    addCustomPrice: "Kiểm tra",
    warningMsg:
      "Bạn có chắc chắn muốn cập nhật trạng thái hợp đồng kinh doanh này không?",
    yes: "Có",
    no: "Không",
    successMsg: "Trạng thái hợp đồng đã được cập nhật thành công!",
    customPriceMsg:
      "Bạn có chắc chắn muốn đặt giá đặc biệt cho Khách Hàng này không?",
    inputPlaceholder: "Nhập Giá Đặc Biệt",
    priceEmptyError: "Giá Đặc Biệt không thể để trống!",
    alreadySetPriceError:
      "Giá cho sản phẩm này đã được đặt cho Khách Hàng này trước đó.",
    cancelBtn: "Hủy",
    submit: "Gửi",
    modalTitle: "Đặt Giá Đặc Biệt",
    productsSectionTitle: "Danh Sách Sản Phẩm Giá Đặc Biệt",
    updatePriceTitle: "Cập Nhật Giá Đặc Biệt",
    deletePriceTitle: "Xóa Sản Phẩm khỏi Giá Đặc Biệt",
    productDetailTitle: "Chi Tiết Sản Phẩm",
    offerDetailsTitle: "Chi Tiết Ưu Đãi",
    description:
      "Bạn có chắc chắn muốn xóa sản phẩm này khỏi Giá Đặc Biệt không?",
    notFound: "Không tìm thấy",
    loader: "Vui lòng đợi",
    update: "Cập nhật",
    cancel: "Hủy",
    updateStatus: "Cập nhật Trạng thái Hợp đồng",
    orderForecast: "Tạo Đơn Hàng Tương Lai",
    updateCloseDate: "Cập nhật Ngày Đóng Cửa Thăm",
    closeDate: "Ngày Đóng Cửa Thăm",
    closeDateSuccessMessage: "Cập nhật Ngày Đóng Cửa Thăm Thành Công",
    createdFilterLabel: "Được tạo bởi tôi",
    assignedFilterLabel: "Được giao cho tôi",
  },
  newCustomer: {
    pageTitle: "Tạo Doanh Nghiệp Mới",
    mobileNo: "Số Điện Thoại",
    customerNoHolder: "Số điện thoại doanh nghiệp",
    customerName: "Tên Người Dùng",
    role: "Vai trò",
    selectRole: "Chọn Vai trò",
    customerNameHolder: "Nhập đầy đủ tên người dùng",
    businessPhoto: "Ảnh Doanh Nghiệp",
    uploadPhoto: "Tải ảnh Doanh Nghiệp/Cửa hàng lên",
    uploadPhotoTitle: "Tải ảnh lên",
    uploadFromGallery: "Tải lên từ Thư viện ảnh",
    takePhoto: "Chụp ảnh",
    businessName: "Tên Doanh Nghiệp",
    businessNameHolder: "Nhập tên doanh nghiệp/cửa hàng",
    address: "Địa chỉ",
    addressHolder: "Nhập địa chỉ doanh nghiệp/cửa hàng",
    city: "Thành phố",
    cityHolder: "Nhập tên thành phố",
    postalCode: "Mã Bưu chính",
    postalCodeHolder: "Nhập mã bưu chính",
    selectBusiness: "Chọn Loại Doanh Nghiệp",
    fmcg: "FMCG",
    nonFmcg: "NON-FMCG",
    nextBtn: "Tiếp Theo",
    completeBtn: "Hoàn Thành",
    customerFindText: `Doanh nghiệp đã được tìm thấy ở đây; bạn có thể chọn thêm vào danh sách khách hàng của mình nếu bạn muốn.`,
    addBusiness: "Thêm Doanh Nghiệp",
    addBusinessInfo:
      "Bạn có muốn thêm doanh nghiệp này làm khách hàng của bạn không?",
    noBtn: "Không",
    alreayExist: "Doanh nghiệp đã tồn tại!",
    reseller: "Người bán lại",
    businessNameWarning: "Vui lòng thêm Tên Doanh Nghiệp",
    distributorNameWarning: "Vui lòng thêm Tên Người Dùng",
    businessCreationSuccess: "Tạo Doanh Nghiệp Mới Thành Công",
    businessLocationWarning: "Vui lòng thêm Vị trí Doanh Nghiệp từ Google Map",
  },
  checkIn: {
    pageTitle: "Check In",
    remark: "Ghi chú",
    remarkPlaceholder: "Vui lòng viết thông tin Check In của bạn",
    imageTitle: "Tải Ảnh Thăm",
    checkOutBtnTitle: "Check Out",
    successTitle: "Tốt Lắm",
    checkInMessage: "Check In của bạn đã được hoàn tất thành công",
    checkOutMessage: "Check Out của bạn đã được hoàn tất thành công",
    nextBtnTitle: "Tiếp Theo",
    imageSizePlaceholder: "Dung lượng tệp tối đa hỗ trợ 2MB, JPG, JPEG, PNG",
    checkInSuccessMessage: "Check-in thành công!",
    checkOutSuccessMessage: "Check-out thành công!",
    uploadImage: "Tải Ảnh lên",
    imageUpload: "Tải Ảnh lên",
    uploadFromGallery: "Tải Ảnh từ Thư viện",
    takeYourImage: "Chụp Ảnh của Bạn",
  },
  createBusinessInfo: {
    pageTitle: "Thông Tin Quản Lý Hợp Đồng",
    title: "Thông Tin Doanh Nghiệp",
    brandsLabel: "Phân Phối Thương Hiệu",
    brandsPlaceholder: "Nhập Tên Thương Hiệu",
    skusLabel: "Tổng Số SKU",
    skusPlaceholder: "Nhập Số Lượng SKU",
    srsLabel: "Tổng Số SR",
    srsPlaceholder: "Nhập Số Lượng SR",
    driversLabel: "Tổng Số Tài Xế",
    driversPlaceholder: "Nhập Số Lượng Tài Xế",
    customersLabel: "Tổng Số Khách Hàng",
    customersPlaceholder: "Nhập Số Lượng Khách Hàng",
    salesLabel: "Doanh Số Bán Hàng Hàng Tháng",
    salesPlaceholder: "Nhập Tổng Doanh Số Bán Hàng",
    fsLabel: "Số Tiền Hỗ Trợ Tài Chính Hàng Tháng",
    fsPlaceholder: "Nhập Số Tiền Hỗ Trợ Tài Chính",
    pwFsLabel: "Tuần Dự kiến cho FS Cần",
    pwFsPlaceholder: "Chọn tuần",
    operationLabel: "Ngày Bắt đầu Hoạt động Doanh nghiệp",
    operationExtraLabel: "Năm",
    operationPlaceholder: "Chọn Năm",
    aeLabel: "Phản Hồi AE",
    aePlaceholder: "Nhập Phản Hồi AE",
    nextBtn: "Tiếp Theo",
    owner: "Chủ nhân",
    manager: "Quản lý",
    stuff: "Nhân viên",
    validationLabel: "Vui lòng điền địa chỉ để tiếp tục.",
    okBtnLabel: "Đồng ý",
    successMessage: "Thông tin doanh nghiệp được tạo thành công",
    brandError: "Nhập tên thương hiệu",
    skuError: "Nhập số lượng SKU",
    srError: "Nhập số lượng SR",
    driverError: "Nhập số lượng tài xế",
    customerError: "Nhập số lượng khách hàng",
    salesError: "Nhập số lượng bán hàng",
    fsError: "Nhập số lượng hỗ trợ tài chính",
    operationError: "Chọn năm bắt đầu hoạt động",
    feedbackError: "Nhập ý kiến của bạn",
    regionLabel: "Khu vực",
    areaLabel: "Khu vực",
    regionError: "Chọn một khu vực",
    areaError: "Chọn một khu vực",
    areaPlaceholderWarning: "Vui lòng chọn một khu vực trước",
    pwFsError: "Vui lòng chọn một tuần",
    interestedAreaPageTitle: "Khu vực quan tâm",
    interestedAreaQuestionTitle:
      "Loại lĩnh vực nào mà doanh nghiệp này quan tâm?",
    software: "Phần mềm",
    financing: "Tài chính",
    usedSoftware: "Phần mềm đang sử dụng",
    usedSoftwareError: "Vui lòng nhập tên phần mềm",
    usedSoftwarePlaceholder: "Nhập tên phần mềm đang sử dụng",
    usedSoftwareProblem: "Điểm đau",
    usedSoftwareProblemError: "Vui lòng nhập điểm đau",
    usedSoftwareProblemPlaceholder: "Nhập điểm đau",
  },
  customerDetails: {
    AEUserLabel: "Nhân viên tài khoản",
    HSuserLabel: "Nhân viên Trung tâm",
    visitType: "Loại Hành trình",
    createdAt: "Được tạo lúc",
    createdBy: "Được tạo bởi",
    updatedAt: "Được cập nhật lúc",
    updatedBy: "Được cập nhật bởi",
    businessCardTitle: "Thông tin doanh nghiệp",
    contactCardTitle: "Thông tin liên hệ",
    contractCardTitle: "Thông tin hợp đồng",
    visitCardTitle: "Thông tin thăm",
    name: "Tên",
    number: "Số",
    role: "Vai trò",
    orderType: "Loại đơn hàng",
    serviceType: "Loại dịch vụ",
    revenueTier: "Mức doanh thu",
    expectedRevenue: "Doanh thu dự kiến hàng tháng",
    expectedCloseDate: "Ngày đóng dự kiến",
    status: "Trạng thái",
    checkIn: "Thời gian check-in",
    checkOut: "Thời gian check-out",
    remark: "Chú ý",
    location: "Địa điểm",
    image: "Hình ảnh",
  },
  createContact: {
    pageHeadline: "Thông tin liên hệ",
    nameLabel: "Tên",
    namePlaceholder: "Nhập tên",
    numberLabel: "Số",
    numberPlaceholder: "Nhập số",
    roleError: "Chọn một vai trò",
  },
  createContract: {
    pageHeadline: "Thông tin hợp đồng",
    orderTypeLabel: "Loại đơn hàng",
    serviceTypeLabel: "Loại dịch vụ",
    revenueTierLabel: "Mức doanh thu",
    statusLabel: "Trạng thái",
    selectPlaceholder: "Chọn",
    monthlyRevenueLabel: "Doanh thu dự kiến hàng tháng",
    monthlyRevenuePlaceholder: "Nhập doanh thu dự kiến hàng tháng",
    expectedCloseDateLabel: "Ngày đóng dự kiến",
    expectedCloseDatePlaceholder: "Chọn ngày đóng",
    primaryOrder: "Chính",
    secondaryOrder: "Phụ",
    tertiaryOrder: "Thứ ba",
    successMessage: "Hợp đồng được tạo thành công",
    statusError: "Chọn một trạng thái",
    closeDateError: "Chọn một ngày đóng",
    monthlyRevenueError: "Nhập số lượng doanh thu hàng tháng",
    revenueTierError: "Chọn một mức doanh thu",
    serviceTypeError: "Chọn một loại dịch vụ",
    orderTypeError: "Chọn một loại đơn hàng",
  },
  orderForecast: {
    supplierLabel: "Nhà Cung Cấp",
    supplierPlaceholder: "Chọn một nhà cung cấp",
    supplierError: "Vui lòng chọn một nhà cung cấp",
    deliveryDateLabel: "Ngày Có Thể Nhận Hỗ Trợ Tài Chính",
    deliveryDatePlaceholder: "Chọn một ngày",
    deliveryDateError: "Vui lòng chọn một ngày",
    orderAmountLabel: "Số Lượng Đặt Hàng",
    orderAmountPlaceholder: "Nhập số lượng đặt hàng",
    orderAmountError: "Vui lòng nhập số lượng đặt hàng",
    financeSupportLabel: "Hỗ Trợ Tài Chính",
    financeSupportPlaceholder: "Nhập hỗ trợ tài chính dự kiến",
    financeSupportError: "Vui lòng nhập hỗ trợ tài chính dự kiến",
    btnLabel: "Xác Nhận",
    successMessage: "Đơn Hàng Tương Lai Đã Được Tạo Thành Công",
    selectSupplierPageTitle: "Danh Sách Nhà Cung Cấp",
    supplierSearchPlaceholder: "Tìm kiếm nhà cung cấp của bạn",
  },
};
export default vietnamese;
