import {
	IonApp,
	IonButton,
	IonCol,
	IonGrid,
	IonPage,
	IonRow,
} from "@ionic/react";
import LocationPin from "assets/images/icon/svg/LocationPin";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import { setMapLocation } from "redux/container/userSlice";
import { getCreateUserBusiness, setCreateUserBusiness } from "config/user";

export default function LocationEnabled() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let dispatch = useDispatch();

	//get city
	const getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_2" === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	//get area
	const getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	//get state
	const getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					"administrative_area_level_1" === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	let setLocation = () => {
		if (navigator.geolocation) {
			let location = navigator.geolocation;
			if (location && Object.keys(location).length === 0) {
				navigator.geolocation.getCurrentPosition((p, e) => {
					dispatch(
						setLocation({
							type: "Point",
							coordinates: [p.coords.latitude, p.coords.longitude],
						})
					);
					Geocode.fromLatLng(p.coords.latitude, p.coords.longitude).then(
						(response) => {
							const addressArray =
									response.results[0].address_components,
								city = getCity(addressArray),
								area = getArea(addressArray),
								state = getState(addressArray);
							let locationBuilder = {
								city,
								area,
								state,
								lat: p.coords.latitude,
								lng: p.coords.longitude,
								address: `${city} ${area} ${state}`,
							};

							const business = getCreateUserBusiness();
							business.long_lat = {
								type: "Point",
								coordinates: [p.coords.longitude, p.coords.latitude],
							};

							setCreateUserBusiness(business);
							// dispatch(setMapLocation(locationBuilder));
						},
						(error) => {
							console.error(error);
						}
					);
				});
			}
		} else {
			toast.error("Geolocation is not supported by this browser.");
		}
	};

	return (
		<IonApp>
			<IonPage>
				<div className='px-2 pt-24 relative change-password-screen business-location'>
					<IonGrid>
						<IonRow>
							<IonCol size='12'>
								<div className='text-center'>
									<span className='business-loation-pin'>
										<LocationPin />
									</span>
									<h5 className='text-18 text-black-1000 font-bold pb-3 pt-7'>
										{ekkLanguage.location.title}
									</h5>
									<p className='text-2 text-black-500 font-normal'>
										{ekkLanguage.location.paragraph}
									</p>
								</div>
							</IonCol>
						</IonRow>
						<div className='submit-button-wrapper'>
							<IonRow>
								<IonCol size='12'>
									<div className='text-center'>
										<IonButton
											onClick={() => {
												setLocation();
												// window.location.reload();
											}}
											expand='full'
											className='bg-primary rounded-[7px] font-extrabold text-12'>
											{ekkLanguage.location.btnLabel}
										</IonButton>
									</div>
								</IonCol>
							</IonRow>
						</div>
					</IonGrid>
				</div>
			</IonPage>
		</IonApp>
	);
}
