import React from "react";

export default function ContractUser({ color = "white" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70825 9.50753C10.2333 9.50753 12.2573 7.45729 12.2573 4.89959C12.2573 2.3419 10.2333 0.291656 7.70825 0.291656C5.18323 0.291656 3.15917 2.3419 3.15917 4.89959C3.15917 7.45729 5.18323 9.50753 7.70825 9.50753ZM7.70825 11.7639C3.99962 11.7639 0.833252 12.3569 0.833252 14.7259C0.833252 17.094 3.98036 17.7083 7.70825 17.7083C11.416 17.7083 14.5833 17.1154 14.5833 14.7463C14.5833 12.3773 11.4361 11.7639 7.70825 11.7639ZM17.2397 6.78886H18.3425C18.7965 6.78886 19.1666 7.16419 19.1666 7.62451C19.1666 8.08484 18.7965 8.46016 18.3425 8.46016H17.2397V9.53934C17.2397 9.99966 16.8705 10.375 16.4156 10.375C15.9617 10.375 15.5916 9.99966 15.5916 9.53934V8.46016H14.4906C14.0358 8.46016 13.6666 8.08484 13.6666 7.62451C13.6666 7.16419 14.0358 6.78886 14.4906 6.78886H15.5916V5.71064C15.5916 5.25032 15.9617 4.87499 16.4156 4.87499C16.8705 4.87499 17.2397 5.25032 17.2397 5.71064V6.78886Z"
        fill={color}
      />
    </svg>
  );
}
