import { IonCol, IonImg, IonRow } from "@ionic/react";
import React from "react";

export default function MainBanner({ ekkLanguage, lang }) {
  return (
    <div>
      <p className="text-center font-medium text-16 leading-6">
        {ekkLanguage.jobSelect.titlePart1}
        <br />
        {lang === "en" && (
          <span>
            {" "}
            <span>As a</span>
            <span className="font-bold text-[#F94B41]">
              {" "}
              {ekkLanguage.jobSelect.ekkHero}
            </span>
          </span>
        )}
        {lang === "bn" && (
          <span>
            {" "}
            <span className="font-bold text-[#F94B41]">
              {ekkLanguage.jobSelect.ekkHero}
            </span>{" "}
            হিসেবে
          </span>
        )}
        {ekkLanguage.jobSelect.titlePart2}

        <span className="font-bold text-[#00B224]">
          {ekkLanguage.jobSelect.earnMoney}
        </span>
      </p>
      <div className="w-full flex justify-center items-center mt-3">
        <IonRow>
          <IonCol className={`rounded-md relative `}>
            <IonImg
              className="w-[74px] h-[110px]"
              src="/assets/images/ekkhero/Icons/boy.png"
              alt="boy"
            ></IonImg>
          </IonCol>
          <IonCol className={`rounded-md relative `}>
            <IonImg
              className="w-[74px] h-[110px]"
              src="/assets/images/ekkhero/Icons/girl.png"
              alt="girl"
            ></IonImg>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
}
