import { createSlice, createSelector } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		loading: false,
		data: {},
		location: false,
		locationData: null,
		bottomSheet: false,
		referBottomSheet: false,
		pwaDownloadData: {},
		mapLocation: {},
		createUserData: {},
		selectedJob: {},
		allInfo: {},
		createCustomerBS: false,
		customerDetails: false,
	},
	reducers: {
		setUser: (state, action) => {
			state.data = action.payload;
		},
		setLocation: (state, action) => {
			state.location = true;
			state.locationData = action.payload;
		},
		setBottomSheet: (state, action) => {
			state.bottomSheet = action.payload;
		},
		setReferBottomSheet: (state, action) => {
			state.referBottomSheet = action.payload;
		},
		setPwaDownloadData: (state, action) => {
			state.pwaDownloadData = action.payload;
		},
		setMapLocation: (state, action) => {
			state.mapLocation = action.payload;
		},
		setCreateUserData: (state, action) => {
			state.createUserData = action.payload;
		},
		setSelectedJob: (state, action) => {
			state.selectedJob = action.payload;
		},
		setAllInfo: (state, action) => {
			state.allInfo = action.payload;
		},
		createCustomerBS: (state, action) => {
			state.createCustomerBS = action.payload;
		},
		setCustomerDetails: (state, action) => {
			state.customerDetails = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = userSlice;

export const selectors = {
	getLocation: createSelector(
		(state) => state.user,
		(location) => location
	),
};

export const setLocation = (values) => async (dispatch, getState) => {
	dispatch(actions.setLocation(values));
};
export const setUser = (data) => async (dispatch, getState) => {
	dispatch(actions.setUser(data));
};
export const setBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBottomSheet(data));
};
export const setReferBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setReferBottomSheet(data));
};
export const setPwaDownloadData = (data) => async (dispatch) => {
	dispatch(actions.setPwaDownloadData(data));
};
export const setMapLocation = (data) => async (dispatch) => {
	dispatch(actions.setMapLocation(data));
};
export const setCreateUser = (data) => async (dispatch) => {
	dispatch(actions.setCreateUserData(data));
};
export const setSelectedJob = (data) => async (dispatch) => {
	dispatch(actions.setSelectedJob(data));
};
export const setAllInfo = (values) => async (dispatch, getState) => {
	let data = { ...getState().user.allInfo, ...values };
	dispatch(actions.setAllInfo(data));
};
export const setCreateCustomerBS = (data) => async (dispatch) => {
	dispatch(actions.createCustomerBS(data));
};
export const setCustomerDetails= (data) => async (dispatch) => {
	dispatch(actions.setCustomerDetails(data));
};

// Export the reducer, either as a default or named export
export default reducer;
