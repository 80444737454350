import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "pages/Auth/Login";
import Home from "pages/MobilePage/Home";
import Map from "pages/MobilePage/Map";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ContactPage from "pages/MobilePage/ContactPage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import LocationPage from "pages/MobilePage/LocationPage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import Error from "pages/MobilePage/Error";
import Landing from "pages/Landing";
import RefundPolicy from "pages/MobilePage/RefundPolicy";
import PrivacyPolicy from "pages/MobilePage/PrivacyPolicy";
import TermsCondition from "pages/MobilePage/TermsCondition";
import PrivateOutlet from "./PrivateOutlet";
import Nid from "pages/MobilePage/Nid";
import TaskList from "pages/MobilePage/TaskList";
import AddressVerify from "pages/MobilePage/AddressVerify";
import WorkVerify from "pages/MobilePage/WorkVerify";
import SkillVerify from "pages/MobilePage/SkillVerify";
import DrivingVerify from "pages/MobilePage/DrivingVerify";
import Refer from "pages/MobilePage/Refer";
import JobDetails from "pages/MobilePage/JobDetails";
import ReferList from "pages/MobilePage/ReferList";
import ReferCodeSubmit from "pages/MobilePage/ReferCodeSubmit";
import JobSelect from "pages/MobilePage/JobSelect";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import LearningDetailsPage from "pages/MobilePage/LearningDetailsPage";
import CMSListPage from "pages/MobilePage/CMSListPage";
import CustomerDetailsPage from "pages/MobilePage/CustomerDetailsPage";
import CreateNewCustomerPage from "pages/MobilePage/CreateNewCustomerPage";
import CustomerCheckInPage from "pages/MobilePage/CustomerCheckInPage";
import BusinessInfoPage from "pages/MobilePage/BusinessInfoPage";
import PickLocationPage from "pages/MobilePage/PickLocationPage";
import CreateContactPage from "pages/MobilePage/CreateContactPage";
import CreateContractPage from "pages/MobilePage/CreateContractPage";
import CustomerCheckOutPage from "pages/MobilePage/CustomerCheckOutPage";
import SuccessPage from "pages/MobilePage/SuccessPage";
import BrandListPage from "pages/MobilePage/BrandListPage";
import OrderForecastFormPage from "pages/MobilePage/OrderForecastFormPage";
import SelectSupplierPage from "pages/MobilePage/SelectSupplierPage";
import PhoneNumberCheck from "pages/LandingPageV2/PhoneNumberCheck";
import PasswordV2 from "pages/LandingPageV2/PasswordV2";
import TokenV2 from "pages/LandingPageV2/TokenV2";
import RegistrationV2 from "pages/LandingPageV2/RegistrationV2";
import ChangePasswordV2 from "pages/LandingPageV2/ChangePasswordV2";
import ChangePasswordSuccessV2 from "pages/LandingPageV2/ChangePasswordSuccessV2";
import InterestedAreaPage from "pages/MobilePage/InterestedAreaPage";

function Mobile() {
  return (
    <Routes>
      <Route path="/*" element={<PrivateOutlet />}>
        <Route path="home" element={<Home />} />
        <Route path="job-details/:id" element={<JobDetails />}></Route>
        <Route path="select-job" element={<JobSelect />} />
        <Route path="registration" element={<Nid />} />
        <Route path="address-verify" element={<AddressVerify />} />
        <Route path="work-verify" element={<WorkVerify />} />
        <Route path="skill-verify" element={<SkillVerify />} />
        <Route path="driving-verify" element={<DrivingVerify />} />
        <Route
          path="submit-refferel-code"
          element={<ReferCodeSubmit />}
        ></Route>
        <Route path="task-list" element={<TaskList />} />
        <Route path="contract-management" element={<CMSListPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="referral-code" element={<Refer />} />
        <Route path="refer-list" element={<ReferList />} />
        <Route path="location" element={<Map />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="change-password" element={<ChangePasswordPage />} />
        <Route
          path="changed-password-success"
          element={<ChangePasswordSuccessPage />}
        />
        <Route path="otp-send" element={<ProfileVarifyPage />} />
        <Route path="otp-submit" element={<ProfileVarifyOtpPage />} />
        <Route path="change-language" element={<ChangeLanguagePage />} />
        <Route path="location-enabled" element={<LocationPage />} />
        <Route path="pick-location/:to" element={<PickLocationPage />} />
        <Route path="learning-details/:id" element={<LearningDetailsPage />} />
        <Route path="customer-details/:id" element={<CustomerDetailsPage />} />
        <Route path="create-customer/:to" element={<CreateNewCustomerPage />} />
        <Route path="customer-check-in" element={<CustomerCheckInPage />} />
        <Route path="customer-check-out" element={<CustomerCheckOutPage />} />
        <Route path="success-page/:successPageType" element={<SuccessPage />} />
        <Route path="create-business-info" element={<BusinessInfoPage />} />
        <Route path="create-contact" element={<CreateContactPage />} />
        <Route path="create-contract" element={<CreateContractPage />} />
        <Route path="select-brands" element={<BrandListPage />} />
        <Route path="future-order-form" element={<OrderForecastFormPage />} />
        <Route path="select-supplier" element={<SelectSupplierPage />} />
        <Route path="interested-area" element={<InterestedAreaPage />} />
        <Route path="*" element={<Error />} />
      </Route>

      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="/" element={<PhoneNumberCheck />} />
      <Route path="/:app" element={<PhoneNumberCheck />} />
      <Route path="/password" element={<PasswordV2 />} />
			<Route path="/registration-new-user" element={<RegistrationV2 />} />
			<Route path='/submit-token' element={<TokenV2 />} />
			<Route path='/change-password' element={<ChangePasswordV2 />} />
      <Route path='/change-password-success' element={<ChangePasswordSuccessV2 />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsCondition />} />
      <Route path="/auto-log-out" element={<AutoLogoutPage />} />
    </Routes>
  );
}

export default Mobile;
