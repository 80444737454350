import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useRef, useState } from "react";
import HomePageBanner from "../../components/mobile-components/HomePage/HomePageBanner";
import RegisterProcess from "../../components/mobile-components/HomePage/RegisterProcess";
import { useDispatch, useSelector } from "react-redux";
import Blog from "services/Blog";
import NavFooter from "components/mobile-components/Common/NavFooter";
import { setPostList } from "redux/container/learningSlice";
import { getLanguage } from "config/language";
import Learning from "components/mobile-components/Learning";
import { setAllInfo } from "redux/container/userSlice";
import User from "services/User";
import Contract from "services/Contract";
import { setUserType } from "redux/container/customerSlice";

function Home() {
  const contentRef = useRef();
  const localLanguage = getLanguage();
  const learningList = useSelector((state) => state.learning.postList);
  const [pageCount, setPageCount] = useState(2);
  const [dashboard, setDashboard] = useState("");
  const [initHeight] = useState(72);
  const [initStickyClass, setInitStikyClass] = useState("issticky");
  const ekkUser = useSelector((state) => state.user.allInfo);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [userTypeData, setUserTypeData] = useState({});

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  let loadMoreData = async () => {
    if (hasMoreData) {
      let response = await Blog.learningList(
        pageCount,
        5,
        localLanguage === "bn" ? "BN" : "EN"
      );
      if (response.status === 200 || response.status === 201) {
        if (response.results.length) {
          setPageCount((prev) => prev + 1);
          dispatch(setPostList([...learningList, ...response?.results]));
        } else {
          setHasMoreData(false);
        }
      } else {
        setHasMoreData(false);
      }
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      let res = await User.checkKycInfo();
      if (res.status === 200 || res.status === 201) {
        dispatch(setAllInfo(res?.results));
        if (res?.results?.kyc_status === "Verified") {
          let userTypeResponse = await User.checkUserType(
            res?.results?.user_id
          );
          if (
            userTypeResponse.status === 200 ||
            userTypeResponse.status === 201
          ) {
            setUserTypeData(userTypeResponse?.data);
            if (userTypeResponse?.data?.user_type === "EkkHero Hub Staff") {
              dispatch(setUserType(userTypeResponse?.data?.user_type));
              let dashboardResponse = await Contract.getBusinessInfoDashboard();
              if (
                dashboardResponse.status === 200 ||
                dashboardResponse.status === 201
              ) {
                setDashboard({
                  visit: dashboardResponse.visit,
                  business_info: dashboardResponse.business_info,
                });
              }
            }
            if (userTypeResponse?.data?.user_type === "EkkHero Captain") {
              let dashboardResponse = await Contract.getBusinessInfoDashboard();
              if (
                dashboardResponse.status === 200 ||
                dashboardResponse.status === 201
              ) {
                setDashboard({
                  visit: dashboardResponse.visit,
                  business_info: dashboardResponse.business_info,
                });
              }
            }
          }
        }
      }
      let response = await Blog.learningList(
        1,
        5,
        localLanguage === "bn" ? "BN" : "EN"
      );
      if (response.status === 200) {
        dispatch(setPostList(response.results));
        setLoading(false);
      }
    };
    getAllData();
  }, [localLanguage, dispatch]);

  return (
    <IonApp>
      <IonPage>
        <IonContent
          scrollEvents={true}
          ref={contentRef}
          onIonScroll={async (ev) => {
            const scrollPosition = ev.detail.scrollTop;
            if (scrollPosition > initHeight) {
              setInitStikyClass("issticky");
            } else {
              setInitStikyClass("issticky");
            }
          }}
        >
          <BackButtonTitle
            title={ekkLanguage.letNav.homeMenuLabel}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <HomePageBanner
            userTypeData={userTypeData}
            ekkUser={ekkUser}
            ekkLanguage={ekkLanguage}
            dashboard={dashboard}
          ></HomePageBanner>
          {ekkUser?.kyc_status !== "Verified" && (
            <RegisterProcess></RegisterProcess>
          )}
          {loading && (
            <IonLoading isOpen={true} message={ekkLanguage.learning.loader} />
          )}
          <section className="px-4 my-4">
            <div className="flex justify-start mb-4">
              <img
                className="w-6 h-6"
                src="/assets/images/learning.png"
                alt=""
              />
              <h6 className="font-bold text-20 ml-2">
                {ekkLanguage.learning.pageTitle}
              </h6>
            </div>
            <Learning
              ekkLanguage={ekkLanguage}
              learningList={learningList}
            ></Learning>
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.learning.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
        <NavFooter />
      </IonPage>
    </IonApp>
  );
}

export default Home;
