import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Map from "components/mobile-components/LocationPicker/Map";
import MapFunctional from "components/mobile-components/LocationPicker/MapFunctional";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function PickLocationPage() {
	const { to } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [error, setError] = useState();
	const location = useSelector((state) => state.user.locationData);
	const myLocation = useSelector(
		(state) => state.user.locationData.coordinates
	);
	console.log(to)
	
	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.locationPicker.pageTitle} />
					<div className='pt-14'>
						{/* <Map
						// google={this.props.google}
						center={{ lat: 23.5204, lng: 90.399452 }}
						height='500px'
						zoom={15}
					/> */}

						<MapFunctional
							center={
								{ lat: myLocation[1], lng: myLocation[0] } ?? {
									lat: 23.5204,
									lng: 90.399452,
								}
							}
							height='400px'
							zoom={18}
							location={location}
							from='pickLocation'
							to={to}
						/>
						<div
							className='mx-4 mt-5 mb-10'
							style={{
								background: "#FFFFCC",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
							}}>
							<div className='rounded-[5px]'>
								<p className='text-808080 text-12 font-semibold px-[10px] py-[6px]'>
									{ekkLanguage.locationPicker.paragraph}
								</p>
							</div>
						</div>
						<div className='text-center'>
							<IonButton
								color='primary'
								className='font-bold h-10'
								onClick={() => {
									console.log(location)
									if (Object.keys(location).length) {
										navigate(`/create-customer/${to}`);
									} else {
										setError(
											// ekkLanguage.businessCreateName.errorLabel
										);
									}
								}}>
								{ekkLanguage.newCustomer.nextBtn}
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}